import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Feature from "../components/Feature";

class HomePijlers extends Component {
  render() {
    return (
      <React.Fragment>
        <section id="home-inleiding" className="services p-t50-b50">
          <div className="container">
            <ScrollAnimation animateIn="fadeInUp">
              <div className="row">
                <div className="col-lg-8 offset-lg-2 text-center">
                  <div className="section-title">
                    <h2>Drie pijlers</h2>
                    <span className="section-title-bg">Pijlers</span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  Werken doe ik vanuit drie pijlers:
                  <Feature />
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default HomePijlers;
