import React, { Component } from "react";
import PropTypes from "prop-types";
import ScrollAnimation from "react-animate-on-scroll";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";

class HomeInleiding extends Component {
  render() {
    return (
      <React.Fragment>
        <section id="home-inleiding" className="services p-t50-b50">
          <div className="container">
            <ScrollAnimation animateIn="fadeInUp">
              <div className="row">
                <div className="col-lg-8 offset-lg-2 text-center">
                  <div className="section-title">
                    <h2>{this.props.sectionTitle}</h2>
                    <span className="section-title-bg">
                      {this.props.SectionbgTitle}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <p className="justify">{this.props.sectionDescription}</p>
              </div>
              <div>
                <h3>Hé hardwerkende en zorgzame vrouw!</h3>
                <div>
                  <ul className="list-style-type-check">
                    <li>
                      Weet je stiekem heel goed dat je al lange tijd te veel
                      ballen in de lucht probeert te houden?
                    </li>
                    <li>
                      Begint je reservetank aardig op te raken en eist dat zowel
                      lichamelijk als mentaal zijn tol?
                    </li>
                    <li>
                      Vind je het lastig om stil te zitten en hangt je veel te
                      lange to-do-list altijd als een donkere wolk boven je
                      hoofd?
                    </li>
                    <li>
                      Maakt pleasen een belangrijk deel uit van jouw dagelijkse
                      bezigheden?
                    </li>
                    <li>
                      Wil je stoppen met enkel zorgzaam zijn voor anderen en ook
                      zorgzaam worden voor jezelf?
                    </li>
                    <li>
                      Wil je opnieuw ontdekken wat jij nu echt wilt, zonder maar
                      gedachteloos door te denderen?
                    </li>
                    <li>
                      Wil je de overlevingsmodus laten plaatsmaken voor meer
                      energie, meer groei en meer in contact zijn met jezelf?
                    </li>
                  </ul>
                  <div>
                    <br />
                    <p>
                      Als natuurcoach houd ik jou graag een liefdevolle spiegel
                      voor. Ik leer je via coaching in de natuur en mindfulness
                      om opnieuw mogelijkheden te zien. Al na een paar
                      coachsessies zie jij weer mogelijkheden om te doen wat je
                      écht wilt.
                    </p>
                  </div>
                </div>
              </div>
              <div className="jadana-link">
                <div className="center-wrap">
                  <Link to="/#contact" className="btn-a">
                    <div className="button right button-double-width">
                      Ik wil mijn mogelijkheden ontdekken
                      <Icofont icon="icofont-long-arrow-right" />
                      <div className="mask" />
                    </div>
                  </Link>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

//Props Types
HomeInleiding.propTypes = {
  SectionbgTitle: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  servicesData: PropTypes.array,
};

//Default Props
HomeInleiding.defaultProps = {
  SectionbgTitle: "Coaching",
  sectionTitle: "Jadana Coaching: wandel- en mindfulnesscoach",
  sectionDescription:
    "Wat als stress ongezond wordt? Als je compleet uit het oog bent verloren wat goed is voor jou, doordat je te veel bezig bent met voldoen aan ieders verwachtingen en(vooral!) aan jouw eigen verwachtingen? Als wandel- en mindfulnesscoach ben ik er voor hardwerkende en zorgzame vrouwen die stress-gerelateerde klachten ervaren. Die klaar zijn met alle ballen in de lucht houden en weer in verbinding willen komen met zichzelf. Want dát zorgt voor meer energie, meer plezier en meer écht leven.",
  servicesData: [],
};

export default HomeInleiding;
