import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';

class DienstenColumns extends Component {

  getWhiteLines(whitelinesBottom) {
    let whiteLines = [];
    for (let i = 0; i < whitelinesBottom; i++) {
      whiteLines.push(<br />);
    }
    return whiteLines;
  }

  getContent(about, wrapperClassName) {
    return (
      <React.Fragment>
        <div className={wrapperClassName}>
          {about.Icon && <i className={about.Icon} />}
          <h3>{about.title}</h3>
            {about.content}
            {about.whitelinesBottom && <span>{this.getWhiteLines(about.whitelinesBottom)}</span>}
        </div>
      </React.Fragment>
    );
  }

  getWrappedContent(about) {
    if (about.isLink === true) {
      return (
        <React.Fragment>
          <Link to={about.link} className="jadana-page-link">
            {this.getContent(about, "about-info")}
          </Link>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {this.getContent(about, "about-info-nolink")}
        </React.Fragment>
      );
    }
  }

  render() {
    //About loop start
    const aboutdata = this.props.aboutData.map((about, index) => (
      <div className="col-lg-4 col-md-6" key={index}>
        {this.getWrappedContent(about)}
      </div>
    ));
    //About loop END
    return (
      <React.Fragment>
        <div className="row">
          {aboutdata}
        </div>
      </React.Fragment>
    );
  }
}
//Props Types
DienstenColumns.propTypes = {
  aboutData: PropTypes.array
};

//Default Props
DienstenColumns.defaultProps = {
  aboutData: [
    {
      Icon: "icofont-compass-alt",
      title: "Individuele coaching",
      content: "Als coach ben ik er om jou te begeleiden en een stukje met je mee te lopen op jouw pad. Ik help je inzicht te krijgen in wat je wilt bereiken en de keuzes die je daarin kunt maken.",
      link: "/wandelcoach",
      whitelinesBottom: 1,
      isLink: true
    },
    {
      Icon: "icon-mindfulness",
      title: "Mindfulness workshops",
      content: "Leef jij nu zoals je zou willen leven? Zijn er dingen die je ooit nog zou willen doen, maar het komt er niet van? Wil je meer genieten en minder druk ervaren?",
      link: "/mindfulness",
      whitelinesBottom: 2,
      isLink: true
    },
    {
      Icon: "icofont-foot-print",
      title: "Wandel workshops",
      content: "Geef jezelf een paar uur in de natuur cadeau! In deze workshop neem je de tijd om jezelf (weer) wat aandacht te geven tijdens een mindful walk door de Amsterdamse Waterleidingduinen.",
      link: "/wandelcoach",
      whitelinesBottom: 0,
      isLink: true
    },
  ]
};

export default DienstenColumns;
