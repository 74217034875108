import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Page from "react-page-loading-restored";
import { CSSTransition } from "react-transition-group";

//Package CSS
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "animate.css/animate.min.css";

//Template CSS Style
import "../src/style.css";
import "./assets/css/responsive.css";
import "./assets/css/color/color-default.css"
import "./assets/css/fonts/style.css"


//Import Component
import Home from "./pages/Home";
import ScrollUpBtn from "./components/ScrollUp";
import Wandelcoach from "./pages/Wandelcoach";
import Mindfulness from "./pages/Mindfulness";
import CoachZandvoort from "./pages/CoachZandvoort";
import OverEsther from "./pages/OverEsther";

class App extends Component {
    render() {
        return (
          <div className="App">
            <Page loader={"comet-spin"} color={"#e9bf87"} size={50}>
              <div>
                <Route
                  render={({ location }) => (
                    <CSSTransition
                      key={location.key}
                      timeout={{ enter: 900, exit: 900 }}
                      classNames="fade"
                    >
                      <section className="route-section">
                        <Switch location={location}>
                          <Route path="/wandelcoach" component={Wandelcoach} />
                          <Route path="/mindfulness" component={Mindfulness} />
                          <Route
                            path="/coach-zandvoort"
                            component={CoachZandvoort}
                          />
                          <Route path="/wandelcoach" component={Wandelcoach} />
                          <Route path="/over-esther" component={OverEsther} />
                          <Route path="/" component={Home} />
                          <Redirect to="/not-found" />
                        </Switch>
                      </section>
                    </CSSTransition>
                  )}
                />
                {/* ScrollUpBtn: src/components/ScrollUp.jsx */}
                <ScrollUpBtn />
              </div>
            </Page>
          </div>
        );
    }
}
export default App;
