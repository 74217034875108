import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Icofont from 'react-icofont';
import { NavLink } from "react-router-dom";
import { Link as RegularLink } from "react-router-dom";
import { Link } from "react-scroll";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Container, Nav } from "react-bootstrap";

class NavBar extends Component {
  componentDidMount() {
    let elem = document.getElementById("navbar");
    document.addEventListener("scroll", () => {
      if (window.scrollY > 170) {
        elem.classList.add("menu-shrink");
        elem.classList.add("fixed-top");
      } else {
        elem.classList.remove("menu-shrink");
        elem.classList.remove("fixed-top");
      }
    });
    window.scrollTo(0, 0);

    if (window.location.hash && window.location.hash !== "#home") {
      const id = window.location.hash.substring(1);
      const offset = this.props.SectionProps.find((props) => props.id === id).offset;
      const y = document.getElementById(id).offsetTop + offset;
      window.scrollTo(0, y);
    } 

    let hasServedRequestHash = false;
    if (this.props.requestHash && !hasServedRequestHash) {
      document.querySelector("[data-to='" + this.props.requestHash.substring(1) + "']").click();
      hasServedRequestHash = true;
    }
  }

  closeNavbar(e) {
    window.navigatingTo = e.target.dataset.to;
    if (window.matchMedia("screen and (max-width: 991px)").matches) {
      document.getElementById("collaspe-btn").click();
    }
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Top Header */}
        <div className="top-header">
          <div className="container">
            <div className="row">
              <div className="col-md-7 col-lg-7">
                <div className="address-bar">
                  <ul className="list-inline">
                    <li>
                      <a href={this.props.mailLink}>
                        <Icofont icon="icofont-email" /> {this.props.mail}
                      </a>
                    </li>
                    <li>
                      <a href={this.props.numberLink}>
                        <Icofont icon="icofont-ui-call" /> {this.props.Number}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-5 col-md-5">
                <div className="social-icons">
                  <ul className="list-inline">
                    <li>
                      <a
                        href={this.props.facebookLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Icofont icon="icofont-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={this.props.instagramLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Icofont icon="icofont-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={this.props.linkedinLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Icofont icon="icofont-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Top Header */}

        <Navbar
          id="navbar"
          bg="light"
          expand="lg"
          className="navbar navbar-expand-md navbar-light"
          collapseOnSelect={true}
        >
          <Container>
            <Navbar.Brand className="navbar-brand logo">
              <React.Fragment>
                <LinkContainer exact to="/">
                  <img src={this.props.MainLogo} alt="Logo" />
                </LinkContainer>
              </React.Fragment>
            </Navbar.Brand>

            <Navbar.Brand className="navbar-brand logo-2">
              <React.Fragment>
                <LinkContainer exact to="/">
                  <img
                    className="img-fluid"
                    src={this.props.Logo2}
                    alt="Logo"
                  />
                </LinkContainer>
              </React.Fragment>
            </Navbar.Brand>

            <Navbar.Toggle
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              id="collaspe-btn"
            />
            <Navbar.Collapse id="navbarSupportedContent">
              <Nav className="navbar-nav ml-auto">
                {this.props.pageName === "home" ? (
                  <React.Fragment>
                    <Nav.Item>
                      <Link
                        activeclass="active"
                        to="home"
                        data-to={"home"}
                        spy={true}
                        hashSpy={true}
                        smooth={true}
                        offset={-200}
                        duration={800}
                        className="smooths nav-link"
                        onClick={this.closeNavbar}
                      >
                        Home
                      </Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Link
                        activeclass="active"
                        to="over-esther"
                        data-to={"over-esther"}
                        spy={true}
                        hashSpy={true}
                        smooth={true}
                        offset={-50}
                        duration={800}
                        className="nav-link"
                        onClick={this.closeNavbar}
                      >
                        Over Esther
                      </Link>
                    </Nav.Item>

                    <Nav.Item>
                      <RegularLink
                        to="/wandelcoach"
                        className="nav-link regular-link"
                      >
                        Wandelcoach
                      </RegularLink>
                    </Nav.Item>

                    <Nav.Item>
                      <RegularLink
                        to="/mindfulness"
                        className="nav-link regular-link"
                      >
                        Mindfulness
                      </RegularLink>
                    </Nav.Item>

                    <Nav.Item>
                      <Link
                        activeclass="active"
                        to="aanbod"
                        data-to={"aanbod"}
                        spy={true}
                        hashSpy={true}
                        smooth={true}
                        offset={-100}
                        duration={800}
                        className="nav-link"
                        onClick={this.closeNavbar}
                      >
                        Aanbod
                      </Link>
                    </Nav.Item>

                    <Nav.Item>
                      <Link
                        activeclass="active"
                        to="contact"
                        data-to={"contact"}
                        spy={true}
                        hashSpy={true}
                        smooth={true}
                        offset={-100}
                        duration={800}
                        className="nav-link"
                        onClick={this.closeNavbar}
                      >
                        Contact
                      </Link>
                    </Nav.Item>

                  </React.Fragment>
                ) : (
                    <React.Fragment>
                      <Nav.Item>
                        <NavLink to="/" className="nav-link" activeClassName="">
                          Home
                      </NavLink>
                      </Nav.Item>

                      <Nav.Item>
                        <NavLink to="/#over-esther" className="nav-link" activeClassName="">
                          Over Esther
                      </NavLink>
                      </Nav.Item>

                      <Nav.Item>
                        <NavLink to="/Wandelcoach" className="nav-link">
                          Wandelcoach
                      </NavLink>
                      </Nav.Item>

                      <Nav.Item>
                        <NavLink to="/Mindfulness" className="nav-link">
                          Mindfulness
                      </NavLink>
                      </Nav.Item>

                      <Nav.Item>
                        <NavLink to="/#aanbod" className="nav-link" activeClassName="">
                          Aanbod
                      </NavLink>
                      </Nav.Item>

                      <Nav.Item>
                        <NavLink to="/#contact" className="nav-link" activeClassName="">
                          Contact
                      </NavLink>
                      </Nav.Item>

                    </React.Fragment>
                  )}
              </Nav>
            </Navbar.Collapse>

          </Container>
        </Navbar>
      </React.Fragment>
    );
  }
}
//Props Types
NavBar.propTypes = {
  mailLink: PropTypes.string,
  mail: PropTypes.string,
  numberLink: PropTypes.string,
  Number: PropTypes.string,
  facebookLink: PropTypes.string,
  twitterLink: PropTypes.string,
  instagramLink: PropTypes.string,
  linkedinLink: PropTypes.string,
  MainLogo: PropTypes.string,
  Logo2: PropTypes.string,
  SectionProps: PropTypes.array,
};

//Default Props
NavBar.defaultProps = {
  MainLogo: require('../assets/img/logo.png'),
  Logo2: require('../assets/img/logo2.png'),
  mailLink: "mailto:esther@jadana-coaching.nl",
  mail: "esther@jadana-coaching.nl ",
  numberLink: "callto:+31624908095",
  Number: "06-24908095",
  facebookLink: "//facebook.com/jadana-coaching",
  linkedinLink: "//linkedin.com/in/esther-stet-bijnen/",
  instagramLink: "//instagram.com/jadanacoaching/",
  SectionProps: [{
    id: "home",
    offset: -200
  }, {
    id: "over-esther",
    offset: -50
  }, {
    id: "aanbod",
    offset: -100
  }, {
    id: "tarieven",
    offset: -100
  }, {
    id: "contact",
    offset: -100
  }]
};
export default NavBar;
