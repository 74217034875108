import React, { Component } from "react";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";
import MetaTags from "../../node_modules/react-meta-tags";
import DienstenColumns from "../components/DienstenColumns";

//Import Components
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

class CoachZandvoort extends Component {
  getWhiteLines(whitelinesBeforeButton) {
    let whiteLines = [];
    for (let i = 0; i < whitelinesBeforeButton; i++) {
      whiteLines.push(<br />);
    }
    return whiteLines;
  }

  getContentLeft(contentInfo) {
    return (
      <React.Fragment>
        <div>
          <p>
            Wil je met een specifiek thema aan de slag? Ik begeleid je als coach
            in Zandvoort al wandelend bij jouw vraagstuk. Je krijgt inzicht in
            wat je wilt bereiken en de keuzes die je daarin kunt maken. Je komt
            letterlijk en figuurlijk in beweging.
          </p>
          <p>
            We gaan aan de slag met een mix van cognitieve oefeningen,
            mindfulness en meditatie. Daardoor leer je ontspannen, ontstaat er
            meer ruimte en kun je je gedachten onderzoeken.{" "}
            <b>
              Je stopt met alsmaar doorgaan en ontdekt waar jij behoefte aan
              hebt en energie van krijgt.
            </b>
          </p>
        </div>
        {contentInfo.whitelinesBeforeSecondHeader && (
          <span>
            {this.getWhiteLines(contentInfo.whitelinesBeforeSecondHeader)}
          </span>
        )}
        <div>
          <h4>Investering</h4>
          € 85 per uur
          <br />
          € 120 per 1,5 uur
          <br />
        </div>
        <div>&nbsp;</div>
        {contentInfo.whitelinesBeforeButton && (
          <span>{this.getWhiteLines(contentInfo.whitelinesBeforeButton)}</span>
        )}
        <div>
          <span>
            <i>Prijzen incl. btw</i>
          </span>
        </div>
        <div>&nbsp;</div>
        <div className="center-wrap">
          <Link to={contentInfo.link} className="btn-a">
            <div className="button center">
              {contentInfo.label}
              <Icofont icon="icofont-long-arrow-right" />
              <div className="mask" />
            </div>
          </Link>
        </div>
      </React.Fragment>
    );
  }

  getContentCenter(contentInfo) {
    return (
      <React.Fragment>
        <div>
          <p>
            Mindfulness is aandacht hebben voor het hier en nu. Het helpt je om
            te midden van dagelijkse prikkels meer stil te staan bij jezelf en
            bij wat er nu is. Je kunt{" "}
            <b>beter omgaan met stress, pijn en ongemak</b>. En je kunt
            gedachten en emoties herkennen, waardoor je niet in niet-helpende
            patronen schiet.
          </p>
          <p>
            Ik bied de officiële <b>8-weekse MBSR-training</b> aan, ontworpen
            door Jon Kabat-Zinn. Dit helpt
            bijvoorbeeld bij stressklachten, als je hoofd vol zit of als je veel
            last hebt van negatieve gedachten. Ook kun je een{" "}
            <b>mindfulness-workshop van 6 sessies</b> volgen.
          </p>
        </div>
        {contentInfo.whitelinesBeforeSecondHeader && (
          <span>
            {this.getWhiteLines(contentInfo.whitelinesBeforeSecondHeader)}
          </span>
        )}
        <div>
          <h4>Investering</h4>
          MBSR-training: € 500 (1-op-1)
          <br />
        </div>
        <div>&nbsp;</div>
        <div>Verkorte workshop van 6 sessies: € 400 (1-op-1)</div>
        <div>&nbsp;</div>
        {contentInfo.whitelinesBeforeButton && (
          <span>{this.getWhiteLines(contentInfo.whitelinesBeforeButton)}</span>
        )}
        <div>
          <span>
            <i>Prijzen incl. btw</i>
          </span>
        </div>
        <div>&nbsp;</div>
        <div className="center-wrap">
          <Link to={contentInfo.link} className="btn-a">
            <div className="button center">
              {contentInfo.label}
              <Icofont icon="icofont-long-arrow-right" />
              <div className="mask" />
            </div>
          </Link>
        </div>
      </React.Fragment>
    );
  }

  getContentRight(contentInfo) {
    return (
      <React.Fragment>
        <div>
          <p>
            Hoe gaat het eigenlijk met jou? Wat speelt er in je leven? En waar
            heb jij nu behoefte aan? Geef jezelf een paar uur in de natuur
            cadeau om in alle rust stil te staan bij die vragen. In deze
            wandelworkshop ga je een <b>mindful wandeling maken</b> door de
            Amsterdamse Waterleidingduinen in Zandvoort. Dé manier om aandacht
            aan jezelf te besteden die jij verdient.
          </p>
          <p>
            De wandelworkshop vindt plaats in een groep van maximaal 12 personen
            en biedt je ruimte voor zelfreflectie en stilte. Je gaat naar huis
            met nieuwe inzichten en een goede dosis frisse lucht.
          </p>
        </div>
        {contentInfo.whitelinesBeforeSecondHeader && (
          <span>
            {this.getWhiteLines(contentInfo.whitelinesBeforeSecondHeader)}
          </span>
        )}
        <div>
          <h4>Investering</h4>
          Wandelworkshop 2 uur: € 27,50
          <br />
        </div>
        <div>&nbsp;</div>
        <div>Mindful stiltewandeling 1,5 uur: € 15 </div>
        <div>&nbsp;</div>
        <div>Mini-retraite 3 uur: € 45</div>
        <div>&nbsp;</div>
        {contentInfo.whitelinesBeforeButton && (
          <span>{this.getWhiteLines(contentInfo.whitelinesBeforeButton)}</span>
        )}
        <div>
          <span>
            <i>Prijzen incl. btw</i>
          </span>
        </div>
        <div>&nbsp;</div>
        <div className="center-wrap">
          <Link to={contentInfo.link} className="btn-a">
            <div className="button center">
              {contentInfo.label}
              <Icofont icon="icofont-long-arrow-right" />
              <div className="mask" />
            </div>
          </Link>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const columns = [
      {
        title: "Individuele (wandel)coaching",
        content: this.getContentLeft({
          link: "/wandelcoach",
          label: "Lees meer",
          whitelinesBeforeButton: 3,
          whitelinesBeforeSecondHeader: 1,
        }),
        isLink: false,
      },
      {
        title: "Mindfulness-training",
        content: this.getContentCenter({
          link: "/mindfulness",
          label: "Lees meer",
          whitelinesBeforeButton: 1,
        }),
        isLink: false,
      },
      {
        title: "Wandelworkshop",
        content: this.getContentRight({
          link: "/wandelcoach",
          label: "Lees meer",
          whitelinesBeforeSecondHeader: 1,
        }),
        isLink: false,
      },
    ];

    return (
      <React.Fragment>
        <MetaTags>
          <title>
            Jouw gecertificeerd coach in Zandvoort bij stressklachten
          </title>
          <meta
            id="meta-description"
            name="description"
            content="Ervaar jij veel stress? Als gecertificeerd coach in Zandvoort help ik jou ontspannen en ontdekken wat JIJ wilt. Al in een paar sessies. Vergoeding mogelijk."
          />
        </MetaTags>
        {/*NavBar: src/components/NavBer.jsx */}
        <NavBar />

        <div className="bread-cumbs-area bread-cumbs-bg">
          <div className="diplay-table">
            <div className="display-table-cell">
              <div className="container">
                <div className="row">
                  <div className="col-lg-10">
                    <p>&nbsp;</p>
                    <h1>Coach Zandvoort</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section id="blog" className="our-blog main-blog">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-12">
                <div className="over-mij">
                  <div className="over-mij-content">
                    <h3>
                      Stop met de automatische piloot, ontdek waar JIJ blij van
                      wordt.
                    </h3>
                    <p>
                      Hé, hardwerkende en zorgzame vrouw. Ben je al een tijdje
                      uit het oog verloren waar jij blij van wordt, waar jij
                      energie van krijgt en wat goed is voor jou? Houd je meer
                      ballen in de lucht dan je aan kunt? Is het voldoen aan
                      andermans verwachtingen jouw voornaamste dagelijkse
                      bezigheid? Als coach in Zandvoort begeleid ik vrouwen die
                      stress ervaren in hun dagelijks leven. En die er klaar
                      voor zijn niet meer alleen zorgzaam te zijn voor anderen
                      maar ook voor zichzelf.
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#contact" className="btn-a">
                          <div className="button right">
                            Contact
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <h3>Herken jij dit?</h3>
                    <ul className="list-style-type-disc">
                      <li>
                        Merk je aan alles (lichamelijk en mentaal) dat je op je
                        reservetank leeft?
                      </li>
                      <li>
                        Heb je het gevoel dat jij van alles MOET doen (want als
                        jij het niet doet wie dan wel?!)?
                      </li>
                      <li>
                        Is de balans al een tijdje zoek en komt nietsdoen in
                        jouw agenda niet voor?
                      </li>
                      <li>
                        Sta je vaak al moe op en is je to-do-list het eerste
                        waar je ’s ochtends aan denkt?
                      </li>
                      <li>
                        Weet je niet meer zo goed wat je wilt? Omdat je vooral
                        doorgaat zonder erbij na te denken?
                      </li>
                      <li>
                        Wil je vaak te veel en is ‘Druk!’ jouw standaard
                        antwoord op de vraag hoe het met je gaat?
                      </li>
                      <li>
                        Heb je het idee vast te lopen en vind je het lastig om
                        oplossingen te zien?
                      </li>
                      <li>
                        <b>
                          Wil je ontdekken hoe je de overlevingsmodus verruilt
                          voor meer energie, meer plezier en meer in contact
                          zijn met jezelf?
                        </b>
                      </li>
                    </ul>
                    <p>
                      <b>
                        Je staat er niet alleen voor. Laat mij je dan helpen van
                        je automatische piloot af te komen.
                      </b>{" "}
                      Jij verdient het om weer te gaan stralen, groeien en
                      denken in mogelijkheden. En daar help ik jou als coach in
                      Zandvoort graag bij. Ik houd je een spiegel voor, leer je
                      weer bij jezelf te komen en help je om opnieuw te denken
                      in mogelijkheden, waardoor je weer kunt doen waar JIJ
                      energie van krijgt.
                    </p>
                    <p>
                      Een aantal coachsessies zijn vaak al genoeg om je weer op
                      weg te helpen en weer mogelijkheden te zien, zodat je kunt
                      doen wat je echt wilt.
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#aanbod" className="btn-a">
                          <div className="button right">
                            Aanbod
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-12 work-details-container">
                      <div className="work-details work-details-text single-feature ">
                        <div className="about-me">
                          <h3>Vergoeding door de verzekering</h3>
                          <p>
                            Als gecertificeerd mindfulnesscoach ben ik
                            aangesloten bij de Vereniging voor Mindfulness. Mijn
                            coaching wordt daarom vergoed door de VGZ-groep en
                            Zilveren Kruis als je aanvullend verzekerd bent. Dat
                            geldt voor de trainingen en voor individuele
                            coaching. Raadpleeg je verzekering voor meer
                            informatie.
                          </p>
                        </div>
                      </div>
                    </div>
                    <h3>Esther Bijnen: jouw coach in Zandvoort</h3>
                    <p>
                      Mijn naam is Esther Bijnen.{" "}
                      <b>Gecertificeerd mindfulnesscoach</b> (aangesloten bij de
                      Vereniging voor Mindfulness). Ik help vrouwen met
                      stressgerelateerde klachten op hun levenspad en help hen
                      om weer in verbinding te komen met zichzelf. Als je leeft
                      op de automatische piloot door de drukte van alledag kom
                      je er nauwelijks aan toe stil te staan en te voelen waar
                      jij behoefte aan hebt. Dat omdraaien is waar ik jou bij
                      help. Altijd op een open en nieuwsgierige manier, zodat
                      jij je snel op je gemak voelt.
                    </p>
                    <p>
                      Ik heb ruime ervaring in het bedrijfsleven als{" "}
                      <b>leidinggevende en re-integratiecoach</b>. Daarnaast ben
                      ik zelf <b>ervaringsdeskundige</b> op het gebied van
                      stress. Ook ik was die vrouw die altijd alle ballen in de
                      lucht wilde houden – op het werk, thuis en in mijn
                      omgeving. Niet zeuren, maar doorgaan. Totdat ik zelf
                      richting een burn-out ging. Door mindfulness leerde ik om
                      uit de overlevingsmodus te komen en te stoppen met altijd
                      drie dingen tegelijk doen. Ik ging mijn grenzen aanvoelen,
                      keuzes afwegen, hulp vragen en ‘nee’ zeggen.
                    </p>
                    <p>
                      Nu help ik andere vrouwen met veel plezier om hetzelfde te
                      doen. Dat doe ik altijd met coaching op maat. Want wat
                      voor de een werkt, hoeft voor de ander niet te werken. Het
                      enige wat altijd hetzelfde is, is dat ik werk vanuit mijn
                      hart. Het mooiste aan mijn werk? Zien hoe vrouwen
                      veranderen. Dat iemand vaak zoekend naar antwoorden aan
                      een traject begint en aan het eind met een opgeheven hoofd
                      en meer zelfvertrouwen rondloopt. Dát is waar ik het voor
                      doe.
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#over-esther" className="btn-a">
                          <div className="button right">
                            Over Esther
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <h3>
                      Mijn aanbod voor coaching en mindfulness in Zandvoort
                    </h3>
                    <p>
                      Bij mij als coach in Zandvoort kun je terecht voor
                      wandelcoaching, wandelworkshops en trainingen in en
                      workshops op het gebied van mindfulness in Zandvoort.
                      Bekijk mijn aanbod hieronder.
                    </p>
                    <DienstenColumns aboutData={columns} />
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div>
                      <p>
                        Ook bied ik als coach in Zandvoort{" "}
                        <b>coaching aan voor bedrijven</b>. Dit kan zijn in de
                        vorm van individuele (wandel)coaching, wandelworkshops
                        om kennis te maken met mindfulness en met
                        mindfulness-trainingen of workshops.
                      </p>
                    </div>
                    <div className="col-md-12 work-details-container">
                      <div className="work-details work-details-text single-feature ">
                        <div className="about-me">
                          <h3>
                            “Als je doet wat je altijd deed, zal je krijgen wat
                            je altijd kreeg.”
                          </h3>
                          <p>Albert Einstein</p>
                        </div>
                      </div>
                    </div>
                    <h3>Wat als stress ongezond wordt?</h3>
                    <p>
                      Je hebt een bepaalde dosis stress nodig om doelen te
                      behalen. Maar als het chronisch wordt, kan het je gaan
                      uitputten en krijgt je lichaam niet de rust om te
                      herstellen van een periode van stress. Je maakt langdurig
                      stresshormonen aan, waardoor je in de overlevingsmodus
                      belandt. Dit kan lichamelijke en mentale klachten of zelfs
                      een burn-out veroorzaken. Denk aan vermoeidheid, geen
                      beslissingen kunnen nemen, somberheid of angst, sociaal
                      isolement, slaapproblemen, rugklachten, hoofdpijn of
                      heftige emotionele reacties.
                    </p>
                    <p>
                      Als mindfulnesscoach help ik jou met coaching en
                      mindfulness in Zandvoort om{" "}
                      <b>je bewust te worden van stress-signalen</b>. Je leert
                      ze op tijd herkennen, zodat je jezelf kunt beschermen
                      tegen alsmaar doordenderen. Ook in lastige periodes. Ik
                      help je daarmee voor de lange termijn. Je leert je eigen
                      patronen herkennen en weer mogelijkheden zien, waardoor je
                      blijvend kunt veranderen.
                    </p>
                    <h3>Wat levert het je op?</h3>
                    <p>
                      Met mijn coaching en mindfulness in Zandvoort bereik je
                      het volgende:
                    </p>
                      <ul className="list-style-type-check">
                        <li>
                          Je kunt het leven leiden zoals jij dat wilt in plaats
                          van maar te blijven doorrennen.
                        </li>
                        <li>
                          Je hebt inzicht in jouw niet-helpende patronen en kunt
                          daar op tijd een stokje voor steken.
                        </li>
                        <li>
                          Je kunt beter voor jezelf zorgen, je agenda leger
                          maken en bewuster keuzes maken.
                        </li>
                        <li>
                          Je weet dat je niet meer alles MOET doen en dat het
                          ook niet allemaal NU moet. En je kunt daarnaar
                          handelen.
                        </li>
                        <li>
                          Je komt weer in verbinding met jezelf, waardoor je
                          beter beseft waar jij blij van wordt en wat je energie
                          geeft. En wat niet.
                        </li>
                        <li>
                          Je weet wat je tegenhoudt in de keuzes die je maakt en
                          kunt dat omdraaien.
                        </li>
                        <li>
                          Je doet weer waar je blij van wordt. Dat merk je aan
                          je energie, je humeur én je hele gesteldheid. Je
                          straalt weer en je groeit.
                        </li>
                      </ul>
                    <h3>Zullen we kennismaken?</h3>
                    <p>
                      Een goede klik is natuurlijk de basis van een
                      coachingstraject. Daarom maak ik graag via videobellen
                      kennis met je. Bel of mail me. Dan ga ik graag met je in
                      gesprek om samen af te tasten of en hoe ik je kan helpen.
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#contact" className="btn-a">
                          <div className="button right">
                            Contact
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*Footer: src/components/Footer.jsx */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default CoachZandvoort;
