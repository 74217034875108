import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from 'react-router-dom';

class Hero extends Component {
    render() {
        return (
            <React.Fragment>
                <div id="home" className="hompage-slides-wrapper">
                    <div className="single-slider-item slide-bg-1">
                        <div className="diplay-table">
                            <div className="display-table-cell">
                                <VisibilitySensor>
                                    {({ isVisible }) => (
                                    <div className="container">
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <span
                                                    className={
                                                        isVisible
                                                            ? "hero-text animated fadeInDown slow opacityOne"
                                                            : "opacityZero"
                                                    }
                                                >
                                                    {this.props.TopTitle}
                                                </span>

                                                <h1
                                                    className={
                                                        isVisible
                                                            ? "animated fadeInDown slow opacityOne"
                                                            : "opacityZero"
                                                    }
                                                >
                                                    {this.props.Title}
                                                </h1>
                                                <p
                                                    className={
                                                        isVisible
                                                            ? "animated fadeInDown slow opacityOne"
                                                            : "opacityZero"
                                                    }
                                                >
                                                    {this.props.Content}
                                                </p>
                                                {/* <div className="center-wrap">
                                                    <div className="center-wrap">
                                                        <Link to={this.props.BtnLink} className="btn-a">
                                                            <div className="button">
                                                                {this.props.BtnName}
                                                                <Icofont icon="icofont-long-arrow-right" />
                                                            <div className="mask" /></div>
                                                        </Link>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                )}
                                </VisibilitySensor>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
//Props Types
Hero.propTypes = {
    TopTitle: PropTypes.string,
    Title: PropTypes.string,
    Content: PropTypes.string,
    BtnLink: PropTypes.string,
    BtnName: PropTypes.string,
};

//Default Props
Hero.defaultProps = {
    TopTitle: "Welkom bij",
    Title: "Jadana coaching",
    Content: "Jadana coaching staat voor verbinding. In verbinding zijn begint bij jezelf. Als je in verbinding bent met jezelf weet je waar je blij van wordt, waar je energie van krijgt en wat goed voor je is.",
    BtnLink:  "/#",
    BtnName: "Aan de slag",
};

export default Hero;
