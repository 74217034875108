import React, { Component } from "react";
import PropTypes from "prop-types";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";

const getYear = () => {
  return new Date().getFullYear();
};

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container">
            <div className="row">
              <div className="col-md-4 text-right">
                <a href="/coach-zandvoort" className="white">
                  ✓ Coach Zandvoort
                </a>
              </div>
              <div className="col-md-3 text-center">
                <a href="/mindfulness" className="white">
                  ✓ Mindfulness
                </a>
              </div>
              <div className="col-md-5 text-left">
                <a href="/wandelcoach" className="white">
                  ✓ Wandelcoach
                </a>
              </div>
            </div>
            <div className="row">&nbsp;</div>
            <div className="row">
              <div className="col-md-4">
                <p className="copyright">{this.props.copyrightText}</p>
              </div>
              <div className="col-md-3">
                <p className="coc">{this.props.cocRegistration}</p>
              </div>
              <div className="col-md-5">
                <div className="social-icons bottom">
                  <ul className="list-inline">
                    <li>{this.props.socialTitle} </li>
                    <li>
                      <Link
                        to={this.props.FacebookLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Icofont icon="icofont-facebook" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={this.props.InstagramLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Icofont icon="icofont-instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={this.props.linkedinLink}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <Icofont icon="icofont-linkedin" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}
//Props Types
Footer.propTypes = {
  copyrightText: PropTypes.string,
  FacebookLink: PropTypes.string,
  TwitterLink: PropTypes.string,
  InstagramLink: PropTypes.string,
  linkedinLink: PropTypes.string,
  cocRegistration: PropTypes.string,
};

//Default Props
Footer.defaultProps = {
  copyrightText: getYear() + " © Alle rechten voorbehouden.",
  socialTitle: "Volg Jadana coaching op:",
  FacebookLink: "//facebook.com/jadana-coaching",
  linkedinLink: "//linkedin.com/in/esther-stet-bijnen/",
  InstagramLink: "//instagram.com/jadanacoaching/",
  cocRegistration: "KvK-nummer: 80119247",
};
export default Footer;
