import React, { Component } from 'react';
import Icofont from 'react-icofont';
import PropTypes from "prop-types";

class Feature extends Component {
  render() {
      //Feature loop start
      const featuredata = this.props.featuresData.map((feature, index) => (
        <div className="col-lg-4 col-md-4" key={index}>
            <div className="single-feature">
                <div className="glyph">
                    <i className={feature.icon}></i>
                </div>
                <h3>{feature.heading}</h3>
                <p>{feature.description}</p>
            </div>
        </div>
    ));
    //Feature loop END
    return (
        <React.Fragment>
            <section className="feature-area">
                <div className="container">
                    <div className="row">
                        {featuredata}
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
  }
}

//Props Types
Feature.propTypes = {
    featuresData: PropTypes.array
};

//Default Props
Feature.defaultProps = {
    featuresData: [
        {
            icon: "icon-third-eye",
            heading: "Inzicht",
            description:
                "Inzicht in jezelf en de wereld om je heen, zorgt ervoor dat jij de touwtjes in handen hebt in je eigen leven."
        },
        {
            icon: "icon-seed-of-life",
            heading: "In verbinding",
            description:
                "In verbinding zijn begint bij jezelf, als je weet waar je je bed voor uitkomt, wat je belangrijk vindt en nodig hebt."
        },
        {
            icon: "icon-puzzle-heart",
            heading: "Inspiratie",
            description:
                "Inspiratie komt uit datgene waar je blij van wordt, de dingen die je doet vanuit je hart."
        }
    ]
};

export default Feature;
