import React, { Component } from "react";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

//Import Components
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const nextTraining = () => {
  const now = new Date();

  if (now.getMonth() === 0 && now.getDate() <= 15) {
    return "januari " + now.getFullYear();
  } else if (((now.getMonth() === 0 && now.getDate() > 15) || now.getMonth() >= 1) && now.getMonth() < 3 && !(now.getMonth() === 2 && now.getDate() > 15)) {
    return "maart " + now.getFullYear();
  } else if (((now.getMonth() === 2 && now.getDate() > 15) || now.getMonth() >= 3) && now.getMonth() < 6 && !(now.getMonth() === 5 && now.getDate() > 15)) {
    return "juni " + now.getFullYear();
  } else if (((now.getMonth() === 5 && now.getDate() > 15) || now.getMonth() >= 6) && now.getMonth() < 9 && !(now.getMonth() === 8 && now.getDate() > 15)) {
    return "september " + now.getFullYear();
  } else if (now.getMonth() >= 9 || (now.getMonth() === 8 && now.getDate() > 15)) {
    return "januari " + (now.getFullYear() + 1);
  }
};
class Mindfulness extends Component {  
  componentDidMount() {
    const aanbodLink = document.getElementById('aanbod-link');

    if (aanbodLink) {
      aanbodLink.onclick = () => {
        document.getElementById('aanbod-anchor').scrollIntoView();
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>
            Mindfulness-training: leer niet-helpende patronen doorbreken
          </title>
          <meta
            id="meta-description"
            name="description"
            content="Wil jij af van niet-helpende gedachten, gepieker en stress? Bekijk hoe een mindfulness-training of -workshop je kan helpen. Praktische insteek. In Zandvoort."
          />
        </MetaTags>
        {/*NavBar: src/components/NavBer.jsx */}
        <NavBar />

        <div className="bread-cumbs-area bread-cumbs-bg">
          <div className="diplay-table">
            <div className="display-table-cell">
              <div className="container">
                <div className="row">
                  <div className="col-lg-10">
                    <h1>&nbsp;</h1>
                    <h1>Mindfulness-training en workshop mindfulness</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section id="blog" className="our-blog main-blog">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-12">
                <div className="over-mij">
                  <div className="over-mij-content">
                    <h3>
                      Krijg weer overzicht, leer meer genieten en ervaar rust in
                      je overvolle hoofd
                    </h3>
                    <p>
                      Heb jij last van een continue stroom aan gedachten? Voel
                      je je opgejaagd, moe of overprikkeld? Dender je maar door
                      en is genieten er nauwelijks nog bij? Sta je niet meer
                      stil bij wat jij écht wilt, maar doe je dingen vooral
                      “omdat je ze altijd zo doet” en niet beter weet. Volg dan
                      een mindfulness-training of een workshop mindfulness.
                      Krijg zo praktische handvatten om aan te voelen wat goed
                      is voor jou, je grenzen te bewaken en te werken aan je
                      persoonlijke ontwikkeling.
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#contact" className="btn-a">
                          <div className="button right button-double-width">
                            Meld je aan voor de mindfulness-training
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <h3>Heb jij last van een overvol hoofd en stress?</h3>
                      <ul className="list-style-type-check">
                        <li>
                          Ga jij gebukt onder stress of angst? En heb je
                          daardoor mentale of fysieke klachten?
                        </li>
                        <li>
                          Staat jouw hoofd nooit echt stil en heb je altijd wel
                          iets om over te piekeren?
                        </li>
                        <li>
                          Ben je het overzicht verloren en ren je als een kip
                          zonder kop alsmaar door en door?
                        </li>
                        <li>
                          Ben je meer bezig met wat “zou moeten” dan met wat jij
                          nu eigenlijk wilt?
                        </li>
                        <li>
                          Wil je meer vrijheid ervaren in je keuzes en loskomen
                          van wat je “altijd doet”?
                        </li>
                        <li>
                          Wil je ontdekken hoe je negatieve of belemmerende
                          overtuigingen de laan uit stuurt?
                        </li>
                        <li>
                          <b>
                            Wil je meer ontspanning ervaren en daardoor ook
                            ontdekken wat JIJ wilt? Zonder stress, gepieker of
                            negatieve gedachten? En mét meer rust?
                          </b>
                        </li>
                      </ul>
                    <p>
                      Dan kan mindfulness jou verder helpen. Als gevoelens en
                      gedachten je niet meer beheersen, kun je beter voelen wat
                      jij écht wilt in het leven en daar je aandacht op richten.
                      Met dat heldere doel voor ogen laten hobbels en ongemakken
                      onderweg zich gemakkelijker uit de weg ruimen. Dát is wat
                      mindfulness je leert.
                    </p>
                    <p>
                      In de <b>8-weekse mindfulness-training</b> of de{" "}
                      <b>6-weekse workshop mindfulness</b> krijg jij alle
                      praktische handvatten aangereikt om mindfulness dagelijks
                      toe te passen. Mindfulness is namelijk, net als
                      pianospelen of autorijden, een vaardigheid die je kunt
                      trainen. Het gaat je blijvend helpen bij stressklachten,
                      piekeren of negatief denken.
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <div className="btn-a">
                          <div className="button right button-double-width">
                            <span id="aanbod-link" >Bekijk het aanbod</span>
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 work-details-container">
                      <div className="work-details work-details-text single-feature ">
                        <div className="about-me">
                          <h3>Vergoeding door de verzekering</h3>
                          <p>
                            Als gecertificeerd mindfulnesscoach ben ik
                            aangesloten bij de Vereniging voor Mindfulness. Mijn
                            coaching wordt daarom{" "}
                            <b>vergoed door de VGZ-groep en Zilveren Kruis</b>{" "}
                            als je aanvullend verzekerd bent. Dat geldt voor de
                            trainingen en voor individuele coaching. Raadpleeg
                            je verzekering voor meer informatie.
                          </p>
                        </div>
                      </div>
                    </div>
                    <h3>Word je bewust van de automatische piloot</h3>
                    <p>
                      Vaak zijn we geneigd dingen op standje “automatisch” te
                      doen. Niet erg, onbewust reageren zoals je dat hebt
                      geleerd, is soms heel nuttig. Als we bij alles moeten
                      nadenken, zouden veel simpele dingen als aankleden en
                      handen wassen heel tijdrovend zijn. Zelfs over ademhalen
                      zou je dan de hele dag moeten nadenken. Maar je kunt door
                      de automatische piloot ook snel in een sleur terechtkomen,
                      waarbij je onbewust doet wat je altijd al hebt gedaan.
                      Want{" "}
                      <b>
                        als je hetzelfde doet als wat je altijd deed, krijg je
                        ook hetzelfde als wat je altijd hebt gehad
                      </b>
                      .
                    </p>
                    <p>
                      Tijdens periodes van veel druk of stress schieten we vaak
                      in de overlevingsstand. Je maakt stresshormonen aan als
                      cortisol en adrenaline en komt in een vecht- of
                      vluchtmodus. Dat is nuttig omdat je een moeilijke opdracht
                      wilt vervullen of problemen moet oplossen. Maar als dat
                      eenmaal gebeurd is, is het ook belangrijk om weer uit de
                      overlevingsstand te komen en je stresshormonen af te
                      bouwen.
                    </p>
                    <p>
                      Als dat niet lukt en je maar blijft doordenderen, gaat het
                      mis. Je hoofd raakt overvol, je bent het overzicht kwijt
                      en je lijf komt niet meer echt tot rust.{" "}
                      <b>Je handelt automatisch</b>. Je zegt bijvoorbeeld steeds
                      ‘ja’ om mensen niet teleur te stellen. Of je gaat maar
                      door en door terwijl je lichaam schreeuwt om rust. Die
                      automatische stand is dus handig voor de korte termijn,
                      maar het is belangrijk om op enig moment tot rust te
                      komen. Anders kun je bij chronische stress overspannen
                      raken of zelfs een burn-out ontwikkelen.
                    </p>
                    <p>
                      <b>
                        Mindfulness helpt je die automatische piloot te
                        herkennen en je ervan bewust te worden. Daardoor voel je
                        weer dat je een keuze hebt om niet automatisch te
                        handelen en in plaats daarvan te doen wat goed is voor
                        jou.{" "}
                      </b>
                    </p>
                    <h3>Waarom mindfulness?</h3>
                    <p>
                      Mindfulness is{" "}
                      <b>aandacht hebben voor wat er op dit moment gebeurt</b>.
                      Wat voel je? Wat denk je? Wat merk je in je lichaam? En
                      welke gedachten heb je? Bij mindfulness sta je stil bij
                      wat je voelt en merkt – zonder oordeel. Ook bij negatieve
                      gevoelens, want ook die mogen er zijn. Het helpt je daar
                      iets mee te doen of te leren ermee om te gaan.
                    </p>
                    <p>
                      In het hier en nu bevind je je in een ruststand, waardoor
                      je kunt <b>herstellen van stress</b>. Je kijkt
                      bijvoorbeeld heel bewust waarom je je gestrest of
                      onprettig voelt op bepaalde momenten. Zie het als een
                      oefening om jezelf en je onbewuste patronen beter te leren
                      kennen, zodat je daar beter naar kunt handelen.
                    </p>
                    <p>
                      Je leert...
                    </p>
                      <ul className="list-style-type-check">
                        <li>
                          …je gedachten en emoties sneller te herkennen,
                          waardoor je niet meer uit automatisme (op een
                          niet-helpende manier) reageert.
                        </li>
                        <li>
                          …stress, angst en (chronische) pijn beter te hanteren.
                        </li>
                        <li>
                          …te voelen en doen wat je écht wilt en daarbij
                          steviger in je schoenen te staan.
                        </li>
                        <li>
                          …je grenzen eerder te (h)erkennen en daar ook naar
                          handelen.
                        </li>
                        <li>
                          …je eigen patronen en valkuilen te ontdekken (en die
                          waar nodig buiten de deur zetten).
                        </li>
                        <li>
                          …oefeningen toe te passen die je in een diepe
                          ontspanning brengen.
                        </li>
                        <li>
                          …meer ruimte te maken voor je gevoel, je behoeften en
                          je emoties.
                        </li>
                      </ul>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#contact" className="btn-a">
                          <div className="button right button-double-width">
                            Ja, dit wil ik!
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>

                    <h3>Kom los van je gedachten</h3>
                    <p>
                      Heb je het gevoel dat je gedachten je niet loslaten? We
                      kunnen ons suf piekeren over werk, gezondheid, relaties en
                      geld. Ook ben je misschien streng voor jezelf en “moeten”
                      we vaak van alles. Herkenbaar? We steken vaak veel tijd in
                      gedachten en handelen veel “vanuit” die gedachten, in
                      plaats van er gewoonweg zonder oordeel naar te kijken of
                      ze te observeren.
                    </p>
                    <p>
                      Want zijn je gedachten wel de waarheid? Moet je er wel
                      echt iets mee? Misschien niet.{" "}
                      <b>
                        Mindfulness helpt je om je minder door gedachten te
                        laten leiden
                      </b>
                      . Zo kun je meer vanuit je eigen waarden handelen. Je gaat
                      ontdekken wat er allemaal mogelijk is als je de regels van
                      je verstand niet meer voor lief neemt.
                    </p>
                    <p>
                      Je bepaalt zelf of je de 8-weekse mindfulness-training
                      volgt of liever voor de (versnelde) 6-weekse workshop
                      mindfulness kiest.
                    </p>
                    <span id="aanbod-anchor">&nbsp;</span>
                    <h2>8-weekse mindfulness-training</h2>
                    <p>
                      Bij de 8-weekse training mindfulness gaan we aan de slag
                      met <b>Mindfulness Based Stress Reduction</b> (MBSR) Training.
                      Deze methode is ontwikkeld door Jon
                      Kabat-Zinn. Deze mindfulnesstraining is een goede mix van
                      meditatie en mindfulness en oefeningen uit de cognitieve
                      psychologie. Ook maken we een plan van aanpak voor na de
                      training, zodat je blijvend met mindfulness aan de slag
                      kunt.
                    </p>
                    <p>
                      <b>Onderwerpen:</b> automatische piloot, omgaan met
                      hindernissen, bewust zijn van de adem, erbij blijven,
                      uitdagingen bewust beantwoorden, gedachten zijn geen
                      feiten, de beste manier om voor jezelf te zorgen en
                      gebruiken wat je geleerd hebt.
                    </p>
                    <h3>Praktische info</h3>
                      <ul className="list-style-type-disc">
                        <li>
                          De training bestaat uit 9 bijeenkomsten: 8
                          bijeenkomsten van 2,5 uur en een verdiepingsdagdeel.
                        </li>
                        <li>
                          Elke 1 à 2 weken is er een sessie, waarin we starten
                          met een aandachtsoefening en ervaringen delen.
                          Vervolgens gaan we met de nieuwe theorie en oefeningen
                          aan de slag.
                        </li>
                        <li>
                          Na elke sessie ga je met concrete oefeningen naar
                          huis.
                        </li>
                        <li>De sessies vinden plaats in een kleine groep.</li>
                        <li>De training start in {nextTraining()} of eerder in overleg.</li>
                        <li>
                          De sessies vinden plaats in mijn studio of in een
                          gehuurde ruimte in Zandvoort en soms ook buiten.
                        </li>
                        <li>
                          Vergoeding vanuit de aanvullende verzekering bij
                          VGZ-groep en Zilveren Kruis.
                        </li>
                      </ul>
                    <h3>Investering</h3>
                    <p>
                      De investering in jezelf voor deze training bedraagt {" "}
                      <b>€ 500 (incl. btw)</b>.<br />
                      Je ontvangt daarbij ook uitgebreid cursusmateriaal, zoals
                      een werkboek en audiobestanden, waarmee je ook thuis kunt
                      oefenen.
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#contact" className="btn-a">
                          <div className="button right button-double-width">
                            Meer info aanvragen?
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <h2>Workshop mindfulness in 6 sessies</h2>
                    <p>
                      Wil je liever via een versnelde workshop leren mindfulness
                      toe te passen? Dat kan ook. De 6-weekse
                      mindfulness-workshop is de{" "}
                      <b>verkorte versie van de 8-weekse training</b>. Ook is
                      deze praktischer van insteek. Je krijgt tijdens deze
                      workshop handvatten aangereikt om je meer bewust van
                      jezelf te worden. Je leert jezelf beter kennen en steviger
                      in je schoenen te staan. Zo ga je herkennen hoe je
                      reageert op anderen of op taken en hoe dat anders kan. De
                      oefeningen zijn direct toe te passen in de praktijk, privé
                      en op het werk.
                    </p>
                    <h3>Praktische info</h3>
                      <ul className="list-style-type-disc">
                        <li>Je volgt 6 sessies van 1,5 uur.</li>
                        <li>
                        De volgende workshop mindfulness begint in {nextTraining()} of eerder in overleg.
                        </li>
                        <li>
                          De sessies vinden vooral binnen plaats in mijn studio
                          of in een gehuurde ruimte in Zandvoort en soms ook buiten.
                        </li>
                        <li>
                          Vergoeding vanuit de aanvullende verzekering bij
                          VGZ-groep en Zilveren Kruis.
                        </li>
                      </ul>
                    <h3>Investering</h3>
                    <p>
                      Voor deze workshop mindfulness die je handvatten oplevert
                      waar jij nog heel lang profijt van hebt, betaal je een
                      eenmalige investering van <b>€ 400 (incl. btw)</b> voor 6
                      sessies. Je krijgt er ook een werkboek en audio-bestanden
                      bij.
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#contact" className="btn-a">
                          <div className="button right button-double-width">
                            Meer info aanvragen?
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <h3>Over Esther: mindfulnesstrainer en coach</h3>
                    <p>
                      Als <b>mindfulnesstrainer en ervaringsdeskundige</b> weet
                      ik wat stress of de overlevingsstand met je kan doen. Maar
                      belangrijker nog: ik weet wat voor goeds mindfulness met
                      je kan doen. Zeker als je veel piekert, last hebt van
                      negatieve gedachten, vaak gespannen bent, in een burn-out
                      zit of om andere redenen rust in je hoofd kunt gebruiken.{" "}
                    </p>
                    <p>
                      Als <b>gecertificeerd mindfulnesscoach</b> (aangesloten
                      bij de Vereniging voor Mindfulness) help ik vrouwen met
                      stressgerelateerde klachten op hun levenspad en help ik
                      hen weer in verbinding te komen met zichzelf. Op een open
                      en respectvolle manier.{" "}
                    </p>
                    <p>
                      Ik leefde zelf ook heel lang op de automatische piloot.
                      Alsmaar door- en doorgaan voelde goed, maar bracht me
                      weinig goeds. Mindfulness leerde me stilstaan en
                      vertragen. Het zorgde ervoor dat ik weer ging nadenken
                      over wat ik deed en of dat wel echt nodig was. Ik kan nu
                      beter ‘nee’ zeggen, voel mijn grenzen goed aan, kan beter
                      mijn keuzes afwegen en heb van rust een standaardpunt op
                      mijn to-do-list gemaakt.{" "}
                    </p>
                    <p>
                      Mijn missie?{" "}
                      <b>
                        Jou laten zien dat je écht zelf een keuze hebt en dingen
                        anders kunt doen
                      </b>
                      . Het is spannend om je oude patronen aan de wilgen te
                      hangen. Maar dingen anders doen zorgt er ook voor dat je
                      wat anders krijgt dan wat je altijd hebt gekregen. Mag ik
                      jou daarbij helpen?
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/over-esther" className="btn-a">
                          <div className="button right button-double-width">
                            Meer over mij
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <h3>Wat levert het je op?</h3>
                    <p>
                      Of je nu de mindfulness-training volgt of kiest voor de
                      workshop mindfulness, het kan je veel opleveren.
                      Mindfulness is geen quick fix. Door er – ook thuis - nog
                      vaak mee te oefenen, kun je het integreren in je leven.
                      Zie het als een nieuwe leefstijl creëren. Het gaat om het
                      aanleren van nieuwe gewoontes.{" "}
                    </p>
                      <ul className="list-style-type-check">
                        <li>
                          Je leert jezelf beter kennen en krijgt inzicht in je
                          (onbewuste) denkpatronen.{" "}
                        </li>
                        <li>
                          Je denkt en doet minder op de automatische piloot,
                          waardoor je beter kunt aftasten wat jij nodig hebt en
                          wat je grenzen zijn.{" "}
                        </li>
                        <li>
                          Je ervaart meer rust en ontspanning, ongeacht hoeveel
                          stress er van buitenaf op je afkomt.{" "}
                        </li>
                        <li>
                          Je kunt je emoties beter hanteren en komt sneller
                          terug in balans.{" "}
                        </li>
                        <li>
                          Je staat steviger en met meer zelfvertrouwen in het
                          leven.{" "}
                        </li>
                        <li>
                          Je kunt beter slapen en hebt minder last van
                          nachtelijke piekersessies.{" "}
                        </li>
                        <li>
                          Je gaat meer genieten, hebt meer overzicht en je kent
                          je energiegevers en -vreters.
                        </li>
                        <li>
                          <b>
                            Je (h)erkent signalen van je lichaam eerder,
                            waardoor je op tijd aan de bel kunt trekken als je
                            emmer dreigt over te lopen.{" "}
                          </b>
                        </li>
                      </ul>
                    <h3>Wil jij ook meer rust en minder gepieker?</h3>
                    <p>
                      Gun jezelf dan een mindfulness-traject en krijg zo de
                      regie terug in handen. Maak gerust een afspraak voor een
                      video-kennismaking. Dan vertel ik je graag meer over mijn
                      training en workshop en kunnen we kijken of het wat voor
                      jou is.{" "}
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#contact" className="btn-a">
                          <div className="button right button-double-width">
                            Neem contact op
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*Footer: src/components/Footer.jsx */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Mindfulness;
