import React, { Component } from "react";
import ScrollAnimation from "react-animate-on-scroll";
const vvm = require("../assets/img/vvm-logo.png")
const nobco = require("../assets/img/nobco-logo.svg")

class HomeOpleveren extends Component {
  render() {
    return (
      <React.Fragment>
        <ScrollAnimation animateIn="fadeInUp">
          <div className="p-b50">
            <div className="container">
              <h3>Wat levert het je op?</h3>
              <p>
                Met mijn coaching en mindfulness in Zandvoort bereik je het
                volgende:
              </p>
              <ul className="list-style-type-check">
                <li>
                  Het lukt je weer om stil te staan – letterlijk en figuurlijk –
                  en te bedenken wat JIJ wilt.
                </li>
                <li>
                  Je kent je niet-helpende patronen en kunt die signaleren en er
                  op tijd een stokje voor steken.
                </li>
                <li>
                  Je kunt beter voor jezelf zorgen door ‘nee’ te zeggen,
                  bewuster keuzes te maken en niet meer voor alles en iedereen
                  te rennen.
                </li>
                <li>
                  Je “moet” minder en krijgt daardoor meer rust en ruimte in je
                  hoofd. Ook als de wereld om je heen gestrest en gespannen
                  doordendert.
                </li>
                <li>
                  Je bent weer in verbinding met jezelf. Daardoor weet je wat
                  jou blij maakt en wat niet. En kun je op basis daarvan keuzes
                  maken.
                </li>
                <li>
                  Je voelt je fysiek en mentaal beter. Vrolijker, energieker en
                  minder gespannen. Dat merk je en dat merken ook anderen.
                </li>
                <li>
                  Je kunt voelen en doen wat je echt wilt, en staat daarbij
                  steviger in je schoenen.
                </li>
              </ul>
              <div>
                <h3>Accreditatie</h3>
                <p>
                  Ik ben geaccrediteerd door de NOBCO, de Nederlandse Orde van
                  Beroepscoaches. Het lidmaatschap staat voor een deskundige,
                  ervaren en bekwame coach. De kwaliteit wordt gewaarborgd door
                  bij- en nascholing en doordat ik mij houd aan de ethische code
                  voor coaches.{" "}
                </p>
              </div>
              <div className="logo-container" target="new">
                <a href="https://verenigingvoormindfulness.nl/">
                  <img src={vvm} alt="VVM" className="logo" />
                </a>
                <a href="https://www.nobco.nl/" target="new">
                  <img src={nobco} alt="NOBCO" className="logo" />
                </a>
                <a href="https://www.bewusthaarlem.nl" target="_blank">
                  <img src="https://www.bewusthaarlem.nl/bewusthaarlem.png" alt="Bewust Haarlem" className="logo"/>
                </a>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </React.Fragment>
    );
  }
}

export default HomeOpleveren;
