import React, { Component } from "react";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";
import { MDBContainer, MDBRow } from "mdbreact";

//Import Components
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

class OverEsther extends Component {
  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Esther Bijnen: vrouwencoach bij stressklachten</title>
          <meta
            id="meta-description"
            name="description"
            content="Heb jij last van stressklachten? Wil je niet meer krijgen wat je altijd hebt gehad en weer doen wat jou gelukkig maakt? Lees hoe ik jou help als vrouwencoach."
          />
        </MetaTags>
        {/*NavBar: src/components/NavBer.jsx */}
        <NavBar />

        <div className="bread-cumbs-area bread-cumbs-bg">
          <div className="diplay-table">
            <div className="display-table-cell">
              <div className="container">
                <div className="row">
                  <div className="col-lg-10">
                    <h1>&nbsp;</h1>
                    <h1>Over Esther Bijnen: vrouwencoach bij stressklachten</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section id="blog" className="our-blog main-blog">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-12">
                <div className="over-mij">
                  <div className="over-mij-content">
                    <h3>
                      Van automatische piloot en druk, druk, druk naar doen wat
                      jou gelukkig maakt
                    </h3>
                    <p>
                      Mijn naam is Esther Bijnen, 51 jaar.{" "}
                      <b>
                        Natuurliefhebber, eerlijk, respectvol, avontuurlijk en
                        oprecht geïnteresseerd in jouw verhaal
                      </b>
                      . Ik ben vrouwencoach, mindfulnesstrainer en
                      ervaringsdeskundige op het gebied van stress. Ik ken de
                      ernstige gevolgen van stress, maar ken gelukkig ook de
                      positieve effecten die coaching en mindfulness bij stress
                      kunnen hebben. Zeker als je veel piekert, vaak gespannen
                      bent, of om welke reden dan ook rust in je hoofd kunt
                      gebruiken. In die situaties help ik jou opnieuw ontdekken
                      wat jou wél goed doet en gelukkig maakt.
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#aanbod" className="btn-a">
                          <div className="button right button-double-width">
                            Waar kan ik jou bij helpen?
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <h3>
                      Coach voor vrouwen die hun patronen willen doorbreken
                    </h3>
                    <p>
                      Ik zie vaak dat wij vrouwen ons verantwoordelijk voelen
                      voor alles. We vinden door opvoeding, overtuiging en
                      misschien ook DNA dat we maar voor alles moeten zorgen en
                      van alles moeten regelen. Ik heb gezien en zelf ook
                      ervaren dat we hier te ver in kunnen gaan. Máár gelukkig
                      heb ik ook ervaren dat het anders kan.{" "}
                      <b>
                        Daarom help ik nu zorgzame en hardwerkende vrouwen om
                        hun niet-helpende patronen te doorbreken
                      </b>{" "}
                      en weer overzicht te krijgen. Zodat je weer kunt doen wat
                      JIJ wilt in plaats van wat je denkt te moeten.
                    </p>
                    <p>
                      <b>Als gecertificeerd mindfulnesscoach</b> (aangesloten
                      bij de Vereniging voor Mindfulness) help ik vrouwen met
                      stressklachten om weer in verbinding te komen met
                      zichzelf. Weg van standje automatische piloot waar je door
                      de alledaagse drukte in terecht kunt komen, toe naar
                      opnieuw stilstaan en voelen waar jij behoefte aan hebt. Ik
                      maak je bewust van jouw stress-signalen, zodat je die kunt
                      herkennen en voortaan kunt voorkomen dat je alsmaar
                      doordendert.
                    </p>
                    <h3>Mijn missie als vrouwencoach</h3>
                    <p>
                      <b>
                        Jou laten zien dat je écht zelf een keuze hebt en dingen
                        anders kunt doen
                      </b>
                      . Het is spannend om je oude patronen aan de wilgen te
                      hangen. Maar dingen anders doen zorgt er ook voor dat je
                      wat anders krijgt dan wat je altijd hebt gekregen. Te
                      beginnen bij in verbinding zijn met jezelf.
                    </p>
                    <h3>Mijn ervaringsverhaal</h3>
                    <p>
                      Dat ik coach voor vrouwen met stressklachten ben geworden,
                      komt niet uit de lucht vallen. Ik weet waar jij doorheen
                      gaat.{" "}
                      <b>
                        Ook ik was ooit die vrouw die honderd ballen in de lucht
                        wilde houden
                      </b>
                      . In 2008 kreeg mijn man een ernstig motorongeluk,
                      mislukte onze tweede IVF-poging en raakte ik door de
                      nasleep van 9/11 mijn baan in de reisbranche kwijt. Bij
                      mijn nieuwe werkgever, thuis en in mijn omgeving wilde ik
                      alles zo goed mogelijk doen. Ik vergat mijzelf en bleef
                      maar doorgaan. “Niet piepen, maar doorgaan!” riep ik
                      mezelf toe. Totdat de beruchte burn-out akelig dichtbij
                      kwam en ik wel actie móest ondernemen.
                    </p>
                    <p>
                      Ik startte met mindfulness. In het begin vond ik het maar
                      lastig. Zo vond ik het al een uitdaging om langzamer te
                      lopen. Als ik snel liep, voelde ik niets, stond ik niet in
                      verbinding met mezelf en hoefde ik niets te voelen. Dat
                      voelde goed, maar deed me niet goed.{" "}
                      <b>
                        Door mindfulness leerde ik om de overlevingsmodus
                        vaarwel te zeggen
                      </b>
                      . Ik ging vertragen, mijn grenzen aanvoelen, keuzes
                      afwegen, hulp vragen en ‘nee’ zeggen. Ook ging ik opnieuw
                      nadenken over wat ik deed en of dat wel echt nodig was.
                      Daardoor kwam er ruimte voor genieten en rustmomenten.
                    </p>
                    <p>
                      Tuurlijk, ik ervaar heus nog weleens stress. Maar op zo’n
                      moment schiet ik niet meer meteen in de overlevingsmodus,
                      maar neem ik een moment van bezinning. Met veel plezier
                      bied ik coaching voor vrouwen, zodat zij diezelfde
                      verandering kunnen doormaken. Het mooiste aan mijn werk?{" "}
                      <b>Zien hoe vrouwen veranderen</b>. Dat je aan het begin
                      nog heel erg zoekende bent en aan het eind met een
                      opgeheven hoofd en meer zelfvertrouwen rondloopt. Dáár doe
                      ik het als coach voor vrouwen voor.
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#contact" className="btn-a">
                          <div className="button right">
                            Contact
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <h3>Waar ik in geloof?</h3>
                    <MDBContainer>
                      <div className="mdb-lightbox no-margin">
                        <MDBRow>
                          <div>
                            <div className="col-md-12 col-lg-6 work-details-container">
                              <div className="work-details work-details-text single-feature ">
                                <div className="about-me about-me-min-height">
                                  <h3>Focus op het hier en nu</h3>
                                  <p>
                                    Mijn focus ligt op het hier en nu. We gaan
                                    niet het verleden uitkauwen en zijn niet
                                    hele sessies over vroeger aan het praten.
                                    Nee, we focussen op praktische handvatten en
                                    oplossingen, zodat jij sneller resultaat
                                    boekt. We kijken waar jij nu tegen aanloopt
                                    en wat je graag zou willen. Daar maken we
                                    een plan voor.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 col-lg-6 work-details-container">
                              <div className="work-details work-details-text single-feature ">
                                <div className="about-me about-me-min-height">
                                  <h3>Coaching in de natuur</h3>
                                  <p>
                                    Ik werk veel in de natuur, in de Amsterdamse
                                    Waterleidingduinen en op het strand. Mijn
                                    ervaring is dat dat goed werkt. De natuur
                                    haalt je uit je omgeving, in het hier en nu.
                                    De natuur geeft rust en troost, en het
                                    wandelen brengt je letterlijk en figuurlijk
                                    in beweging. Je gaat weer stilstaan bij wat
                                    jou beweegt.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBRow>
                        <MDBRow>
                          <div>
                            <div className="col-md-12 col-lg-6 work-details-container">
                              <div className="work-details work-details-text single-feature ">
                                <div className="about-me about-me-min-height">
                                  <h3>Lange termijn </h3>
                                  <p>
                                    Ik plak geen pleisters, maar help je
                                    blijvend op weg. Ik leer je om je eigen
                                    patronen te herkennen en weer mogelijkheden
                                    te zien. Daardoor kun je écht veranderen. Er
                                    zullen altijd momenten zijn dat het minder
                                    gaat. Daarom krijg je de nodige tools
                                    aangereikt waar je op die momenten naar kunt
                                    grijpen.
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="col-md-12 col-lg-6 work-details-container">
                              <div className="work-details work-details-text single-feature ">
                                <div className="about-me about-me-min-height">
                                  <h3>Aandacht voor jou</h3>
                                  <p>
                                    In mijn trajecten kijk ik naar waar jij
                                    staat en naar wat nodig is. Daar gaan we mee
                                    aan de slag. Ik werk dus niet met één
                                    standaardmethode. Want wat voor de een
                                    werkt, werkt misschien voor de ander niet.
                                    Het enige wat hetzelfde is in mijn aanpak,
                                    is dat ik altijd werk vanuit mijn hart.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </MDBRow>
                      </div>
                    </MDBContainer>
                    <h3>Mijn ervaring en opleidingen</h3>
                    <p>
                      Als klein meisje was ik al nieuwsgierig naar wat mensen
                      dachten en naar wat hen bezighield en blij maakte. Als
                      mensen niet blij waren, wilde ik ze helpen. Dit is altijd
                      zo gebleven en is iets wat bij mij hoort. In mijn{" "}
                      <b>
                        ruim 20 jaar werkervaring als leidinggevende en als
                        re-integratiecoach
                      </b>{" "}
                      heb ik altijd veel te maken gehad met mensen die zichzelf
                      tegenkomen op het werk. Ik wilde iets betekenen, het
                      liefst voor zoveel mogelijk hardwerkende en zorgzame
                      vrouwen. En het liefst voor de lange termijn. Geen
                      pleisters plakken, wel een echte verandering
                      teweegbrengen.
                    </p>
                    <p>
                      Daarom zet ik nu mijn passie voor mensen in als
                      vrouwencoach op het gebied van mindfulness, wandelcoaching
                      en wandelworkshops. Ik doe nu wat ik het leukst vind:
                      mensen coachen op hun levenspad, zodat zij weer in
                      verbinding komen met zichzelf. Naast mijn werkervaring,
                      heb ik in de loop der jaren meerdere tools verzameld om
                      als vrouwencoach{" "}
                      <b>samen met jou op onderzoek uit te gaan</b> naar
                      knelpunten, kwaliteiten of “gewoon” naar waar je je bed
                      voor uitkomt.
                    </p>
                    <ul className="list-style-type-check">
                      <li>
                        Gecertificeerd mindfulnesscoach (aangesloten bij de
                        Vereniging voor Mindfulness)
                      </li>
                      <li>
                        Ruime ervaring als coach, re-integratiecoach en
                        leidinggevende
                      </li>
                      <li>
                        Gecertificeerd als Practitioner Coach bij de Academie
                        voor Psycodynamica
                      </li>
                      <li>
                        Opleidingen Mindfulness- en Compassietrainer, Systemisch
                        werken &amp; Opstellingen, Acceptance Commitment Therapy
                        en Zelfcompassie afgerond aan de Academie voor Leven
                      </li>
                      <li>
                        Opleiding NLP (Neuro Lingu&iuml;stisch Programmeren) afgerond bij
                        NTI
                      </li>
                    </ul>
                    <div>&nbsp;</div>
                    <p>
                      Doordat ik ben aangesloten bij de Vereniging voor
                      Mindfulness, worden mijn trainingen en individuele
                      coaching vergoed door VGZ-groep en Zilveren Kruis (vanuit
                      de aanvullende verzekering).
                    </p>
                    <h3>Wie ben ik nog meer?</h3>
                    <ul className="list-style-type-disc">
                      <li>
                        Niet geheel verrassend: ik ben een echte
                        natuurliefhebber (ook in mijn vrije tijd).
                      </li>
                      <li>
                        Ik ben een avonturier die graag reist en die je mag
                        wakker maken voor het spotten van dieren.
                      </li>
                      <li>
                        Ik ben écht geïnteresseerd in mensen, al van jongs af
                        aan.
                      </li>
                      <li>
                        Ik maak regelmatig mooie wandelvakanties, bijvoorbeeld
                        naar Texel en terug.
                      </li>
                      <li>
                        Ik heb een bijzondere hekel aan koken en ben er ook erg
                        slecht in.
                      </li>
                      <li>
                        Ik zie coaching als een APK-keuring. Zoals je je auto
                        soms laat checken, is het ook heel handig om jezelf soms
                        een mentale keuring te geven.
                      </li>
                      <li>
                        Ik doe als coach wat ik het aller-allerleukst vind om te
                        doen: mensen coachen op hun levenspad, zodat zij weer in
                        verbinding komen met zichzelf. Mag ik ook jou daarbij
                        helpen?{" "}
                      </li>
                    </ul>
                    <h3>Laten we kennismaken</h3>
                    <p>
                      Genoeg verteld over mij. Ik ben nu vooral heel benieuwd
                      naar jou. Wil jij ook de rust en balans vinden? Of wil je
                      je hart volgen maar weet je niet hoe?{" "}
                    </p>
                    <p>
                      Neem dan vrijblijvend contact met me op. Dan kunnen we een
                      videobel-afspraak maken voor een gratis
                      kennismakingsgesprek van 30 minuten.
                    </p>

                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#contact" className="btn-a">
                          <div className="button right">
                            Contact
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*Footer: src/components/Footer.jsx */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default OverEsther;
