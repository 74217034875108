import React, { Component } from "react";
import PropTypes from "prop-types";
import ScrollAnimation from "react-animate-on-scroll";

class VerzekeringKader extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="container p-t50-b50">
          <ScrollAnimation animateIn="fadeInUp">
            <div className="col-md-12 work-details-container">
              <div className="work-details work-details-text single-feature ">
                <div className="about-me greenish-bg">
                  <h3>Vergoeding door de verzekering</h3>
                  <p>
                    Als wandel- en mindfulnesscoach en trainer ben ik lid van de
                    Vereniging Voor Mindfulness (VVM). Daardoor wordt mijn
                    coaching vergoed door VGZ-groep en Zilveren Kruis als je
                    aanvullend verzekerd bent. Dit geldt voor individuele
                    coaching en trainingen. Vraag bij je verzekeraar hoe dit
                    voor jou zit.
                  </p>
                  <p>
                    Daarnaast{" "}
                    <b>vergoedt een groot aantal werkgevers coachtrajecten</b>.
                    Vaak heeft een werkgever een budget gereserveerd voor
                    persoonlijke ontwikkeling of om verzuim tegen te gaan. Vraag
                    naar de mogelijkheden bij je werkgever, vooral als je met
                    coaching een burn-out kunt voorkomen.
                  </p>
                </div>
              </div>
            </div>
          </ScrollAnimation>
        </div>
      </React.Fragment>
    );
  }
}

//Props Types
VerzekeringKader.propTypes = {
  backgroundClass: PropTypes.string,
};

//Default Props
VerzekeringKader.defaultProps = {
  backgroundClass: "",
};

export default VerzekeringKader;
