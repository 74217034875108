import React, { Component } from "react";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

//Import Components
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

class Wandelcoach extends Component {
  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Wandelcoach: individuele coaching in Zandvoort</title>
          <meta
            id="meta-description"
            name="description"
            content="Steek een stokje voor stress en ontdek wat jou wél energie geeft. Als wandelcoach in Zandvoort help ik vrouwen met stressklachten naar meer rust en energie. "
          />
        </MetaTags>
        {/*NavBar: src/components/NavBer.jsx */}
        <NavBar />

        <div className="bread-cumbs-area bread-cumbs-bg">
          <div className="diplay-table">
            <div className="display-table-cell">
              <div className="container">
                <div className="row">
                  <div className="col-lg-10">
                    <h1>&nbsp;</h1>
                    <h1>Wandelcoach: individuele coaching in de natuur</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <section id="blog" className="our-blog main-blog">
          <div className="container">
            <div className="row">
              <div className="col-md-6 col-lg-12">
                <div className="over-mij">
                  <div className="over-mij-content">
                    <h3>
                      Steek een stokje voor stress én ontdek wat jou wél energie
                      geeft.
                    </h3>
                    <p>
                      Houd jij minstens een bal of tien in de lucht? Of beter
                      gezegd: probeer je een poging daartoe te doen, maar breekt
                      het je vooral enorm op? Ren je dagelijks een race tegen de
                      klok en ben je daarbij allang uit het oog verloren wat jou
                      blij maakt? Merk je aan alles dat je gestrest, gespannen
                      of zelfs overspannen bent? Dan is het nu tijd voor actie.
                      Als wandelcoach help ik jou een stokje voor stress te
                      steken, te ontdekken wat jou energie geeft en met frisse
                      moed verder te gaan.
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#contact" className="btn-a">
                          <div className="button right button-double-width">
                            Laten we kennismaken
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                      <h3>Ervaar jij stressklachten?</h3>
                      <ul className="list-style-type-check">
                        <li>
                          Ben je gespannen en is je to-do’s afvinken je grootste
                          missie van de dag?
                        </li>
                        <li>
                          Staan jouw gedachten altijd op standje “aan”, al vanaf
                          ’s ochtends vroeg?
                        </li>
                        <li>
                          Ben je jezelf voorbij gelopen, met lichamelijke en
                          mentale klachten tot gevolg?
                        </li>
                        <li>
                          Ben je altijd bezig met mensen om je heen, maar
                          vergeet je één belangrijk persoon: jijzelf?
                        </li>
                        <li>
                          Denk je altijd van alles te moeten, uit angst dat
                          anders niemand het doet?
                        </li>
                        <li>
                          Leef je op de automatische piloot en ben je uit het
                          oog verloren wat JIJ nu eigenlijk wilt?
                        </li>
                        <li>
                          <b>
                            En ben je eraan toe nu te gaan kijken naar wat jou
                            wél blij maakt? Zonder stress, met handige tools en
                            met een hernieuwde lading energie?
                          </b>
                        </li>
                      </ul>
                    <p>
                      Als wandelcoach zie ik vaak dat wij vrouwen ons al snel
                      verantwoordelijk voelen voor alles. Een mooie maar
                      niet-helpende gedachten. Daarom wil ik jou, lieve en
                      hardwerkende vrouw, helpen om{" "}
                      <b>patronen te doorbreken en weer te doen wat JIJ wilt</b>{" "}
                      in plaats van wat je denkt te moeten doen. We onderzoeken
                      de patronen die jou in de overlevingsmodus hebben gekregen
                      en zorgen ervoor dat jij weer in contact bent met jezelf.
                    </p>
                    <p>
                      Vaak kunnen een paar coachsessies al een wereld van
                      verschil geven. Je ziet weer mogelijkheden, voelt je
                      opgeruimder, energieker en fijner, en weet wat jij wilt en
                      wat je blij maakt.
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#contact" className="btn-a">
                          <div className="button right button-double-width">
                            Ja, ik wil meer weten!
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <h3>Wat is wandelcoaching?</h3>
                    <p>
                      Wandelcoaching is wandelend coachen, of ook wel
                      natuurcoaching. Ik geloof namelijk niet zo in coachen
                      vanuit een stoffige oude stoel met een tissuebox ernaast.
                      Wél in coachen in de buitenlucht in een inspirerende
                      omgeving. Het is wetenschappelijk bewezen dat wandelen
                      goed voor je is – mentaal en fysiek. Bekijk{" "}
                      <a
                        href="https://www.youtube.com/watch?v=wjEH2CSi1-s"
                        target="blank"
                      >
                        dit filmpje van Erik Scherder
                      </a>
                      , hoogleraar klinisch neuropsychologie, maar.
                    </p>
                      <ul className="list-style-type-check">
                        <li>
                          Het helpt enorm dat je uit je eigen omgeving bent.
                        </li>
                        <li>
                          Bovendien kom je door lopend coachen beter in contact
                          met jezelf en met wat je nodig hebt.
                        </li>
                        <li>
                          Je komt <b>letterlijk en figuurlijk in beweging</b>.
                        </li>
                        <li>
                          De natuur straalt rust uit, die je ook kunt voelen en
                          vinden in jezelf. Op die manier kan de natuur je
                          helpen ontspannen, waardoor er meer ruimte in je hoofd
                          ontstaat voor creativiteit en inzichten.
                        </li>
                        <li>
                          Ook kan de natuur je troost bieden. Bijvoorbeeld door
                          de seizoenen die – wat er ook gebeurt - altijd
                          veranderen, door de stilte, de zwanen of spelende
                          hertjes.{" "}
                        </li>
                        <li>
                          Verder houdt de natuur je een spiegel voor. Als je
                          goed kijkt, zullen de dingen die je ziet aansluiten op
                          de antwoorden die je zoekt.
                        </li>
                        <li>
                          De natuur leert je wat mindfulness is. Je bent hier
                          met alles wat er is en de natuur piekert niet over wat
                          anders zou moeten zijn.
                        </li>
                      </ul>
                    <p>
                      Wandelend coachen doen we op het strand of{" "}
                      <b>in de Amsterdamse Waterleidingduinen</b>, waar je langs
                      het water, over open vlaktes en tussen bomen en dieren
                      door wandelt. Tijdens de coaching doe je nieuwe inzichten
                      over jezelf op. Je krijgt handvatten om nu en in
                      toekomstige lastige situaties niet-helpende patronen in te
                      ruilen voor zelfzorg en dingen die jou wel energie geven.{" "}
                    </p>
                    <div className="col-md-12 work-details-container">
                      <div className="work-details work-details-text single-feature ">
                        <div className="about-me">
                          <h3>Vergoeding door de verzekering</h3>
                          <p>
                            Als gecertificeerd mindfulnesscoach ben ik
                            aangesloten bij de Vereniging voor Mindfulness. Mijn
                            coaching wordt daarom{" "}
                            <b>vergoed door de VGZ-groep en Zilveren Kruis</b>{" "}
                            als je aanvullend verzekerd bent. Dat geldt voor de
                            trainingen en voor individuele coaching. Raadpleeg
                            je verzekering voor meer informatie.
                          </p>
                        </div>
                      </div>
                    </div>
                    <h3>
                      Word stress voorgoed de baas – op een snelle, effectieve
                      manier
                    </h3>
                    <p>
                      Als coach houd ik je een spiegel voor en stel ik je de
                      juiste kritische vragen, zodat je zelf tot antwoorden en
                      oplossingen komt. Ik werk niet volgens één
                      standaardmethode. Nee, ik stem mijn aanpak af op jou. Soms
                      werk ik vooral vanuit compassie, maar het kan ook zijn dat
                      je humor fijn vindt of juist een duwtje in de rug nodig
                      hebt. Natuurlijk altijd op een respectvolle en discrete
                      manier.
                    </p>
                    <p>
                      Ook geloof ik niet in eindeloos doorzagen over vroeger.{" "}
                      <b>
                        We kijken waar jij nu tegenaan loopt en wat je zou
                        willen
                      </b>
                      . Daarbij focussen we op praktische handvatten en
                      oplossingen, zodat jij snel resultaat boekt. Je leert je
                      eigen patronen herkennen en weer mogelijkheden te zien.
                      Zodat je blijvend verandert. Daarbij maak ik gebruik van
                      mijn ervaring als manager en re-integratiecoach én van
                      mijn kennis van mindfulness, NLP, systemische coaching,
                      Acceptance Commitment Therapy en zelfcompassie.{" "}
                    </p>

                    <h3>Over Esther: jouw wandelcoach in Zandvoort</h3>
                    <p>
                      Mijn naam is <b>Esther Bijnen</b>. Ik ben wandelcoach en
                      gecertificeerd mindfulnesscoach (aangesloten bij de
                      Vereniging voor Mindfulness). Open, eerlijk, discreet en
                      empathisch. En enorm gepassioneerd over mijn werk.
                      Wandelcoaching is voor mij een combinatie van mijn liefde
                      voor wandelen én mijn enorme drive om meer vrouwen te
                      laten ontdekken hoe zij uit de overlevingsmodus komen en
                      weer kunnen genieten.
                    </p>
                    <p>
                      Ook ik was{" "}
                      <b>
                        ooit die vrouw die alle ballen in de lucht probeerde te
                        houden
                      </b>
                      . Bleek helaas geen groot succes, want na een tijdje zat
                      ik op het randje van een burn-out. Mindfulness leerde me
                      om niet meer steeds drie dingen tegelijk te doen. Ik kwam
                      weer in contact met mezelf, ging mijn grenzen aanvoelen,
                      stond vaker stil, ging oefenen met om hulp vragen en
                      leerde ‘nee’ zeggen. Allemaal vaardigheden die mijn leven
                      hebben verrijkt en die ik ook jou graag laat opdoen.
                    </p>
                    <p>
                      In mijn{" "}
                      <b>
                        jarenlange ervaring als leidinggevende en als
                        re-integratiecoach
                      </b>{" "}
                      heb ik altijd veel te maken gehad met mensen die zichzelf
                      tegenkomen op het werk. Ik wilde iets betekenen, het
                      liefst voor zoveel mogelijk hardwerkende en zorgzame
                      vrouwen. En het liefst voor de lange termijn. Geen
                      pleisters plakken, wel een echte verandering
                      teweegbrengen. Ik vind het dan ook prachtig om er nu in
                      mijn werk voor te zorgen dat jij met meer energie, plezier
                      en zelfvertrouwen kunt verdergaan op je pad.{" "}
                      <i>Mag ik jou daarbij helpen?</i>
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#contact" className="btn-a">
                          <div className="button right button-double-width">
                            Gun jezef minder stress en meer energie
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <h3>Hoe ziet zo’n traject eruit?</h3>
                    <p>
                      Tijdens elke sessie lopen we ongeveer 5-6 kilometer. In
                      een rustig tempo en met pauzes tussendoor. We zullen
                      regelmatig stilstaan of oefeningen doen in de natuur. Soms
                      lopen we na een oefening een stuk in stilte, zodat je
                      inzichten op je kunt laten inwerken. Tijdens de sessie
                      gaan we in op hoe het de afgelopen tijd is gegaan. Ook
                      gaan we op zoek naar wat je echt wilt, wat je in de weg
                      staat, waar je aan wilt werken en hoe je dat gaat
                      aanpakken. Zo komen we stap voor stap – letterlijk en
                      figuurlijk – dichterbij jouw einddoel.
                    </p>
                    <h3>Waar?</h3>
                    <p>
                      De wandelcoaching vindt plaats in de Amsterdamse
                      Waterleidingduinen, een prachtig gebied bij Zandvoort, of
                      op het strand.
                    </p>
                    <p>
                      Mocht je niet tot wandelen in staat zijn, dan is een
                      sessie in mijn studio ook mogelijk.
                    </p>
                    <h3>Hoe?</h3>
                    <p>
                      We gebruiken aan de ene kant meditatie en mindfulness,
                      zodat jij jezelf beter leert kennen, leert ontspannen en
                      ontdekt wat jou helpt om verder te komen. Aan de andere
                      kant werken we met cognitieve oefeningen waarmee je
                      bijvoorbeeld inzicht krijgt in je werk-privébalans en in
                      je energiegevers en -vreters. Zo leer je ontdekken wat je
                      wel en niet leuk vindt in plaats van automatisch maar door
                      te gaan. Verder doen we oefeningen waarbij je gaat werken
                      met en in de natuur, zoals familie- of werkopstellingen of
                      een stilleven maken.
                    </p>
                    <h3>De opbouw</h3>
                    <p>
                      We starten met een <b>kennismaking via videobellen</b>,
                      zodat we elkaar beter leren kennen. En zodat we kunnen
                      kijken of ik jou kan helpen en hoe.
                    </p>
                    <p>
                      Na de kennismaking volgt een <b>intake van 1 uur</b>. Wat
                      wil je en wat juist niet, en wat staat je op dit moment in
                      de weg? Hoe voel je je als je dat hebt bereikt en wat heb
                      je daarvoor nodig? En wat heb je zelf al gedaan voordat je
                      bij mij aanklopte? Op basis van jouw antwoorden maken we
                      samen een plan voor de volgende sessies.{" "}
                    </p>
                    <p>
                      Een traject bestaat daarna uit{" "}
                      <b>3-5 sessies van ongeveer 1,5 uur</b>.{" "}
                    </p>
                    <h3>Thema’s</h3>
                    <p>
                      Tijdens het traject gaan we in op thema’s als
                      re-integratie, stress en timemanagement, persoonlijke
                      ontwikkeling, persoonlijke effectiviteit, de
                      werk-privébalans, communicatie en relaties. Soms zal je
                      huiswerkopdrachten meekrijgen, zoals een
                      aandachtsoefening, een moodboard maken of
                      schrijfopdrachten.
                    </p>
                    <h3>Investering</h3>
                    <p>
                      De investering voor individuele wandelcoaching bedraagt{" "}
                      <b>€ 85 per uur of € 120 per 1,5 uur</b>.
                    </p>
                    <p>
                      <i>Alle prijzen zijn incl. btw.</i>
                    </p>
                    <p>
                      Gun jezelf deze investering qua tijd, geld en ruimte. Jij
                      verdient het om tijd voor jezelf te maken en om niet
                      alleen voor anderen maar juist ook voor jezelf te zorgen.{" "}
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#contact" className="btn-a">
                          <div className="button right button-double-width">
                            Ik gun mezelf wandelcoaching
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <h3>
                      Kennismaken met wandelcoaching? Volg een wandelworkshop!
                    </h3>
                    <p>
                      Ben je nieuwsgierig naar wandelcoaching, maar twijfel je
                      nog of het iets voor jou is? Volg dan een wandelworkshop
                      bij mij in de Amsterdamse Waterleidingduinen. Dit is een
                      eenmalige kennismaking met mindfulness en coaching in de
                      natuur. Zo’n workshop duurt ongeveer 2-2,5 uur.
                    </p>
                    <p>
                      <b>Wandelworkshop (2-2,5 uur):</b> € 27,50<br/>
                      <b>Kennismaken met mindfulness-wandeling (1,5 uur):</b> €
                      15<br/>
                      <b>Mindful stiltewandeling (1,5 uur):</b> € 15
                    </p>
                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#contact" className="btn-a">
                          <div className="button right button-double-width">
                            Ja, ik meld mij aan!
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-12 work-details-container">
                      <div className="work-details work-details-text single-feature ">
                        <div className="about-me">
                          <h3>Aanbod voor bedrijven</h3>
                          <p>
                            Ik verzorg ook individuele wandelcoaching en
                            wandelworkshops voor organisaties. Benieuwd naar de
                            mogelijkheden? Neem contact met mij op.
                          </p>
                        </div>
                      </div>
                    </div>
                    <h3>Wat bereik je ermee?</h3>
                    <p>
                      Het traject levert je het volgende op:
                    </p>
                      <ul className="list-style-type-check">
                        <li>
                          Je weet wat het leven voor jou leuker maakt en wat je
                          energie geeft. Daar stem je je keuzes op af.
                        </li>
                        <li>
                          Je rent niet meer door als een kip zonder kop, maar
                          bent in verbinding met jezelf.
                        </li>
                        <li>
                          <b>
                            Je hebt inzicht in je niet-helpende patronen en kunt
                            die op tijd het raam uit gooien.
                          </b>
                        </li>
                        <li>
                          Je kunt beter voor jezelf zorgen en bewuster keuzes
                          maken.
                        </li>
                        <li>
                          Je kent je valkuilen en obstakels en weet die met man
                          (nee, vrouw) en macht uit de weg te werken.
                        </li>
                        <li>
                          Je hoofd zit minder vol, je hebt meer rust en hebt de
                          touwtjes weer zelf in handen.
                        </li>
                        <li>
                          <b>
                            Je doet wat jou écht blij maakt. Dat merk je mentaal
                            en lichamelijk: je voelt je vrolijker, energieker en
                            minder gespannen. Je STRAALT!
                          </b>
                        </li>
                      </ul>
                    <h3>Zullen we kennismaken?</h3>
                    <p>
                      Ben jij er klaar voor om dingen anders te doen? Om jezelf
                      meer rust, meer energie en minder stress te gunnen? Laten
                      we eens kennismaken via een videogesprek! Dan kunnen we
                      samen aftasten wat ik voor je kan betekenen. Bel of mail
                      me voor een eerste contact.
                    </p>

                    <div className="jadana-link">
                      <div className="center-wrap">
                        <Link to="/#contact" className="btn-a">
                          <div className="button right button-double-width">
                            Neem contact op
                            <Icofont icon="icofont-long-arrow-right" />
                            <div className="mask" />
                          </div>
                        </Link>
                      </div>
                    </div>
                    <p>
                      Esther Bijnen
                      <br />
                      Frans Zwaanstraat 30
                      <br />
                      2042CC Zandvoort
                    </p>
                    <p>
                      E-mail
                      <br />
                      <a href="esther@jadana-coaching.nl">
                        esther@jadana-coaching.nl
                      </a>
                    </p>
                    <p>
                      Telefoon
                      <br />
                      06-24908095
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/*Footer: src/components/Footer.jsx */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Wandelcoach;
