import React, { Component } from "react";
import PropTypes from "prop-types";
import ScrollAnimation from "react-animate-on-scroll";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";

class HomeDoordenderen extends Component {
  render() {
    return (
      <React.Fragment>
        <ScrollAnimation animateIn="fadeInUp">
          <div className="p-b50">
            <div className="container justify">
              <h3>
                Stop met doordenderen, word je bewust van jouw stress-signalen
              </h3>
              <div>
                <p>
                  Herken je dit?{" "}
                  <b>
                    In het dagelijks leven kom je er nauwelijks aan toe om stil
                    te staan en te voelen waar jij behoefte aan hebt
                  </b>
                  . Je ervaart wellicht stress; je gedachten malen soms maar
                  door. Niet gek, want door werkdruk, je smartphone, je volle
                  agenda, de verwachtingen van anderen en (niet te vergeten!) je
                  torenhoge verwachtingen van jezelf ben je vooral druk met
                  allerlei ballen in de lucht te houden.
                </p>
                <p>
                  Voel je je moe en overprikkeld? Wil jij iets wezenlijk
                  veranderen in je leven en ben je er zelf tot nu toe nog niet
                  in geslaagd? Wil jij weer de touwtjes in handen krijgen? Een
                  aantal coachsessies is vaak al genoeg om je op weg te helpen,
                  waardoor je met nieuwe inzichten weer mogelijkheden gaat zien.
                  Ik help je stil te staan en maak je{" "}
                  <b>bewust van jouw stress-signalen</b>. Want als je die op
                  tijd kunt herkennen, kun je jezelf beschermen tegen
                  doordenderen. Ook in lastige periodes en ook op de lange
                  termijn. Zo kun je <b>blijvend veranderen</b>.
                </p>
              </div>
            </div>
          </div>
        </ScrollAnimation>
      </React.Fragment>
    );
  }
}

export default HomeDoordenderen;
