import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter } from "react-router-dom";

import "./index.css";
import App from "./App";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

ReactDOM.render(
  <GoogleReCaptchaProvider
    reCaptchaKey="6LcMhqUZAAAAAHmz_dKV3fRbc4ht3lk-3IvFBbQR"
    language="nl"
    //useRecaptchaNet="[optional_boolean_value]"
  >
    <BrowserRouter basename={"/"}>
      <Route path="/" component={App} />
    </BrowserRouter>
  </GoogleReCaptchaProvider>,
  document.getElementById("root")
);
