import React, { Component } from "react";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import DienstenColumns from "../components/DienstenColumns";

class HomeAanbod extends Component {
  getWhiteLines(whitelinesBeforeButton) {
    let whiteLines = [];
    for (let i = 0; i < whitelinesBeforeButton; i++) {
      whiteLines.push(<br />);
    }
    return whiteLines;
  }

  getContentLeft(contentInfo) {
    return (
      <React.Fragment>
        <div>
          <p>
            Heb jij een gericht thema waar je aan wilt werken? Als natuurcoach
            ben ik er om jou te begeleiden en een stukje met je mee te lopen op
            jouw pad. Je krijgt inzicht in wat je wilt bereiken en de keuzes die
            je daarin kunt maken. Zo kom je letterlijk en figuurlijk in
            beweging.
          </p>
        </div>
        {contentInfo.whitelinesBeforeSecondHeader && (
          <span>
            {this.getWhiteLines(contentInfo.whitelinesBeforeSecondHeader)}
          </span>
        )}
        <div>
          <h4>Investering</h4>
          € 85 per uur
          <br />
          € 120 per 1,5 uur
          <br />
        </div>
        <div>&nbsp;</div>
        <div>
          <h4>Gratis kennismaking</h4>De eerste kennismaking van 30 minuten is
          gratis.
        </div>
        <div>&nbsp;</div>
        {contentInfo.whitelinesBeforeButton && (
          <span>{this.getWhiteLines(contentInfo.whitelinesBeforeButton)}</span>
        )}
        <div>
          <span>
            <i>Prijzen incl. btw</i>
            <br />
            <i>Zakelijk tarief op aanvraag</i>
          </span>
        </div>
        <div>&nbsp;</div>
        <div className="center-wrap">
          <Link to={contentInfo.link} className="btn-a">
            <div className="button center">
              {contentInfo.label}
              <Icofont icon="icofont-long-arrow-right" />
              <div className="mask" />
            </div>
          </Link>
        </div>
      </React.Fragment>
    );
  }

  getContentCenter(contentInfo) {
    return (
      <React.Fragment>
        <div>
          <p>
            Wil je meer genieten en minder druk ervaren? Mindfulness helpt je om
            te midden van de dagelijkse prikkels stil te staan bij wat er nu is.
            Zo kun je beter omgaan met stress, pijn en ongemak. En je kunt
            emoties en gedachten herkennen, waardoor je een stokje voor
            niet-helpende patronen kunt steken.
          </p>
        </div>
        {contentInfo.whitelinesBeforeSecondHeader && (
          <span>
            {this.getWhiteLines(contentInfo.whitelinesBeforeSecondHeader)}
          </span>
        )}
        <div>
          <h4>Investering</h4>
          MBSR-training 1-op-1: € 500
          <br />
        </div>
        <div>&nbsp;</div>
        <div>Verkorte workshop van 6 sessies 1-op-1: € 400</div>
        <div>&nbsp;</div>
        {contentInfo.whitelinesBeforeButton && (
          <span>{this.getWhiteLines(contentInfo.whitelinesBeforeButton)}</span>
        )}
        <div>
          <span>
            <i>Prijzen incl. btw</i>
            <br />
            <i>Zakelijk tarief op aanvraag</i>
          </span>
        </div>
        <div>&nbsp;</div>
        <div className="center-wrap">
          <Link to={contentInfo.link} className="btn-a">
            <div className="button center">
              {contentInfo.label}
              <Icofont icon="icofont-long-arrow-right" />
              <div className="mask" />
            </div>
          </Link>
        </div>
      </React.Fragment>
    );
  }

  getContentRight(contentInfo) {
    return (
      <React.Fragment>
        <div>
          <p>
            Geef jezelf een paar uur in de natuur cadeau. In deze workshop neem
            je de tijd om jezelf (weer) aandacht te geven tijdens een mindful
            wandeling door de duinen. Je gaat opnieuw stilstaan bij hoe het echt
            met je gaat, wat er speelt en waar jij behoefte aan hebt.
          </p>
        </div>
        {contentInfo.whitelinesBeforeSecondHeader && (
          <span>
            {this.getWhiteLines(contentInfo.whitelinesBeforeSecondHeader)}
          </span>
        )}
        <div>
          <h4>Investering</h4>
          Wandelworkshop 2 uur: € 27,50
          <br />
        </div>
        <div>&nbsp;</div>
        <div>Mindful stiltewandeling 1,5 uur: € 15 </div>
        <div>&nbsp;</div>
        {contentInfo.whitelinesBeforeButton && (
          <span>{this.getWhiteLines(contentInfo.whitelinesBeforeButton)}</span>
        )}
        <div>
          <span>
            <i>Prijzen incl. btw</i>
            <br />
            <i>Zakelijk tarief op aanvraag</i>
          </span>
        </div>
        <div>&nbsp;</div>
        <div className="center-wrap">
          <Link to={contentInfo.link} className="btn-a">
            <div className="button center">
              {contentInfo.label}
              <Icofont icon="icofont-long-arrow-right" />
              <div className="mask" />
            </div>
          </Link>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const columns = [
      {
        title: "Individuele (wandel)coaching",
        content: this.getContentLeft({
          link: "/wandelcoach",
          label: "Lees meer"
        }),
        isLink: false,
      },
      {
        title: "Mindfulness-training",
        content: this.getContentCenter({
          link: "/mindfulness",
          label: "Lees meer",
          whitelinesBeforeButton: 2
        }),
        isLink: false,
      },
      {
        title: "Wandelworkshops",
        content: this.getContentRight({
          link: "/wandelcoach",
          label: "Lees meer",
          whitelinesBeforeButton: 3,
          whitelinesBeforeSecondHeader: 1
        }),
        isLink: false,
      },
    ];

    return (
      <React.Fragment>
        <section id="aanbod" className="our-blog main-blog">
          <div className="container">
            <ScrollAnimation animateIn="fadeInUp">
              <div className="row">
                <div className="col-lg-8 offset-lg-2 text-center">
                  <div className="section-title">
                    <h2>Mijn aanbod als wandel- en mindfulnesscoach</h2>
                    <span className="section-title-bg section-title-bg-darker">
                      Aanbod
                    </span>
                  </div>
                </div>
              </div>
              <div>
                Als wandel- en mindfulnesscoach begeleid ik jou met individuele
                (wandel)coaching, wandelworkshops of een mindfulness-training.
                Nooit met een standaardaanpak, altijd met een traject op maat,
                zodat we zeker weten dat jij er zo goed mogelijk bij geholpen
                bent.
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-12">
                  <div className="over-mij">
                    <div className="over-mij-content">
                      <DienstenColumns aboutData={columns} />
                    </div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    Daarnaast bied ik coaching aan voor bedrijven en verzorg ik graag voor jouw medewerkers individuele (wandel)coaching, wandelworkshops ter kennismaking met mindfulness en mindfulness-trainingen of -workshops. 
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default HomeAanbod;
