import React, { Component } from "react";
import PropTypes from "prop-types";
import Icofont from "react-icofont";
import axios from "axios";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import ScrollAnimation from "react-animate-on-scroll";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";

const recaptchaRef = React.createRef();

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      subject: "",
      number: "",
      message: "",
      successMsg: "",
    };
  }

  async componentDidMount() {
    const token = await this.props.googleReCaptchaProps.executeRecaptcha(
      "homepage"
    );
  }

  handleForm = (e) => {
    let that = this;
    var bodyFormData = new FormData();
    bodyFormData.set("name", this.state.name);
    bodyFormData.set("email", this.state.email);
    bodyFormData.set("subject", this.state.subject);
    bodyFormData.set("number", this.state.number);
    bodyFormData.set("message", this.state.message);

    axios
      .post("https://www.jadana-coaching.nl/mailer.php", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(function (response) {
        document.getElementById("contactForm").reset();
        that.setState({
          successMsg: "Dank je! Je bericht is ontvangen.",
        });
        document.getElementById("contactForm").reset();
      })
      .catch(function (error) {});
  };

  handleFields = (e) => this.setState({ [e.target.name]: e.target.value });

  render() {
    return (
      <React.Fragment>
        <section id="contact" className="contact-area p-t100-b150">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 text-center">
                <ScrollAnimation animateIn="fadeInUp">
                  <div className="section-title">
                    <h2>{this.props.sectionTitle}</h2>
                    <p>
                      Ik nodig je graag uit voor meer informatie of een
                      vrijblijvende intake, waarin we samen kunnen kijken of ik
                      voor jou iets kan betekenen.
                    </p>
                    <p>
                      {" "}
                      Maak via het formulier hieronder een eerste afspraak. Een
                      kennismaking van 30 minuten is altijd gratis en
                      vrijblijvend.
                    </p>
                    <span className="section-title-bg section-title-bg-darker">
                      {this.props.SectionbgTitle}
                    </span>
                  </div>
                </ScrollAnimation>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="address-area">
                  <div className="addess">
                    <Icofont icon="icofont-google-map" />
                    <h4>{this.props.AddTitle}</h4>
                    <p>{this.props.Address}</p>
                  </div>
                  <div className="email">
                    <Icofont icon="icofont-email" />
                    <h4>{this.props.EmailTitle}</h4>
                    <p>
                      <a href={this.props.EmailLink}>{this.props.Email}</a>
                    </p>
                  </div>
                  <div className="phone">
                    <Icofont icon="icofont-phone" />
                    <h4>{this.props.PhoneTitle}</h4>
                    <p>
                      <a href={this.props.PhoneLink}>{this.props.Phone}</a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-8 col-md-8">
                <div className="contact-form">
                  <ValidationForm
                    id="contactForm"
                    onSubmit={(e, formData, inputs) => {
                      e.preventDefault();
                      this.handleForm(formData);
                    }}
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <TextInput
                            name="name"
                            id="name"
                            required
                            successMessage=""
                            errorMessage="Vul je naam in"
                            className="form-control"
                            placeholder="Naam"
                            autoComplete="off"
                            onChange={this.handleFields}
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                          <TextInput
                            name="email"
                            id="email"
                            type="email"
                            required
                            successMessage=""
                            errorMessage="Vul je e-mailadres in"
                            className="form-control"
                            placeholder="E-mail"
                            autoComplete="off"
                            onChange={this.handleFields}
                          />
                          <div className="help-block with-errors" />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <TextInput
                            name="subject"
                            id="subject"
                            type="text"
                            successMessage=""
                            errorMessage="Vul een onderwerp in"
                            className="form-control"
                            placeholder="Onderwerp"
                            autoComplete="off"
                            onChange={this.handleFields}
                          />
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6">
                        <div className="form-group">
                          <TextInput
                            name="number"
                            id="number"
                            type="text"
                            successMessage=""
                            errorMessage="Vul je telefoonnummer in"
                            className="form-control"
                            placeholder="Telefoonnummer"
                            autoComplete="off"
                            onChange={this.handleFields}
                          />
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <TextInput
                            name="message"
                            id="description"
                            multiline
                            placeholder="Bericht"
                            className="form-control"
                            required
                            successMessage=""
                            errorMessage="Vul een bericht in"
                            rows="5"
                            autoComplete="off"
                            onChange={this.handleFields}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="center-wrap">
                      <div className="button">
                        <button type="submit">
                          Submit <Icofont icon="icofont-long-arrow-right" />{" "}
                        </button>
                        <div className="mask"></div>
                      </div>
                    </div>
                    <div className="clearfix" />
                  </ValidationForm>
                  {this.state.successMsg !== "" ? (
                    <div className="col-md-12">
                      <div id="contact_send_status">
                        <h3 className="contactMsg">{this.state.successMsg}</h3>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

//Props Types
Contact.propTypes = {
  SectionbgTitle: PropTypes.string,
  sectionTitle: PropTypes.string,
  sectionDescription: PropTypes.string,
  AddTitle: PropTypes.string,
  Address: PropTypes.string,
  EmailTitle: PropTypes.string,
  Email: PropTypes.string,
  EmailLink: PropTypes.string,
  PhoneTitle: PropTypes.string,
  Phone: PropTypes.string,
  PhoneNumberLink: PropTypes.string,
};

//Default Props
Contact.defaultProps = {
  SectionbgTitle: "Contact",
  sectionTitle: "Contact",
  AddTitle: "Adres",
  Address: "Frans Zwaanstraat 30, Zandvoort",
  EmailTitle: "E-mail",
  Email: "esther@jadana-coaching.nl",
  EmailLink: "mailto:esther@jadana-coaching.nl",
  PhoneTitle: "Telefoon",
  Phone: "06-24908095",
  PhoneLink: "callto:+31624908095",
};
export default withGoogleReCaptcha(Contact);
