import React, { Component } from "react";
import PropTypes from "prop-types";
import ScrollAnimation from "react-animate-on-scroll";
import Icofont from "react-icofont";
import { Link } from "react-router-dom";

class OverEsther extends Component {
  render() {
    return (
      <React.Fragment>
        <section id="over-esther" className="cta-area cta-bg-img">
          <div className="container">
          <ScrollAnimation animateIn="fadeInUp">
            <div className="diplay-table">
              <div className="display-table-cell">
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 col-lg-12">
                      <div className="over-mij">
                        <div className="over-mij-content">
                          <div className="cta-info">
                            <h2>{this.props.Title}</h2>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-9 col-lg-9">
                      <div className="over-mij">
                        <div className="over-mij-content">
                          <p>
                            Ik ben Esther Bijnen en ik ben{" "}
                            <b>
                              wandelcoach en gecertificeerd mindfulnesscoach
                            </b>
                            . Ik help vrouwen die door stressklachten tussen de
                            dagelijkse drukte door nauwelijks nog stilstaan bij
                            wat zij nu eigenlijk willen. Als dit ook voor jou
                            geldt, dan help ik je graag om daar verandering in
                            te brengen. Dat doe ik vanuit mijn praktijk met
                            mindfulness en coaching, maar ook in de vorm van
                            natuurcoaching. De natuur brengt letterlijk rust en
                            ruimte, waardoor je kunt stilstaan bij wat jou
                            beweegt.
                          </p>
                          <p>
                            Ik ben, naast coach, ervaringsdeskundige op het
                            gebied van stress.{" "}
                            <b>
                              Ik weet hoe het is om alle ballen in de lucht te
                              willen houden
                            </b>
                            . Op het werk, thuis en in mijn omgeving wilde ik na
                            een pittige periode alles zo goed mogelijk doen. Ik
                            stond altijd aan totdat de burn-out akelig dichtbij
                            kwam. Ik leerde door mindfulness om de
                            overlevingsmodus vaarwel te zeggen. Ik ging mijn
                            grenzen beter aanvoelen, ‘nee’ zeggen en op tijd om
                            hulp vragen. En ik durfde weer voor mezelf te
                            kiezen. Dat voelde zo goed dat ik nu vrouwen coach
                            om hetzelfde te doen.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="offset-3 offset-sm-3 offset-md-0 offset-lg-0 col-6 col-sm-6 col-md-3 col-lg-3">
                      <figure className="padding-top">
                        <img
                          src={this.props.Image}
                          alt="IEsther"
                          className="img-fluid over-esther"
                        />
                      </figure>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="over-mij">
                        <div className="over-mij-content">
                          <p>
                            Als wandel- en mindfulnesscoach bied ik{" "}
                            <b>coaching, trainingen en workshops op maat</b>.
                            Door mijn open en nieuwsgierige houding voelen
                            mensen zich snel op hun gemak. Samen met jou ga ik
                            op onderzoek uit naar je kwaliteiten, je valkuilen
                            en naar wat jou energie geeft. We gaan écht dingen
                            in gang te zetten. Zodat jij na het traject meer
                            ontspannen, met een opgeheven hoofd en met de juiste
                            handvatten verder kunt.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="jadana-link">
              <div className="center-wrap">
                <Link to="/over-esther" className="btn-a">
                  <div className="button right button-double-width">
                      Meer over mij 
                      <Icofont icon="icofont-long-arrow-right" />
                    <div className="mask" />
                  </div>
                </Link>
              </div>
            </div>
          </ScrollAnimation>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

//Props Types
OverEsther.propTypes = {
  Title: PropTypes.string,
  Content: PropTypes.string,
  BtnLink: PropTypes.string,
  BtnName: PropTypes.string,
  Image: PropTypes.string,
};

//Default Props
OverEsther.defaultProps = {
  Title: "Over Esther: wandel- en mindfulnesscoach",
  Content01:
    "In het dagelijks leven kom je er nauwelijks aan toe om even stil te staan en te voelen waar jij behoefte aan hebt. Je ervaart wellicht stress, je gedachten malen soms door. " +
    "Dat komt onder andere door werkdruk, je smartphone, een volle agenda, verwachtingen van anderen en verwachtingen van jezelf. " +
    "Ben je steeds bezig alle ballen in de lucht te houden? ",
  Content02:
    "Voel je je moe en overprikkeld? Wil jij iets wezenlijks veranderen in je leven en ben je er zelf tot nu toe nog niet in geslaagd? Wil jij weer de touwtjes in handen krijgen? ",
  Content03:
    "Ik kan jou hierbij helpen. Een aantal coachsessies is vaak al genoeg om je weer op weg te helpen, waardoor je met nieuwe inzichten weer mogelijkheden gaat zien, zodat jij kunt gaan doen wat je echt wilt.",
  BtnLink: "/#0",
  BtnName: "PURCHASE NOW",
  Image: require("../assets/img/Esther.jpg"),
};
export default OverEsther;
