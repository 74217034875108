import React, { Component } from "react";
import MetaTags from "react-meta-tags";

//Import Component
import NavBar from "../components/NavBar";
import Hero from "../components/Hero";
import HomeInleiding from "../components/HomeInleiding";
import OverEsther from "../components/OverEsther";
import VerzekeringKader from "../components/VerzekeringKader";
import HomeDoordenderen from "../components/HomeDoordenderen";
import HomeAanbod from "../components/HomeAanbod";
import HomePijlers from "../components/HomePijlers";
import HomeOpleveren from "../components/HomeOpleveren";
import Contact from "../components/Contact";
import Footer from "../components/Footer";

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <MetaTags>
          <title>Jadana Coaching: wandel- en mindfulnesscoach Zandvoort</title>
          <meta
            id="meta-description"
            name="description"
            content="Last van fysieke of mentale stressklachten? Als wandel- en mindfulnesscoach help ik jou weer te doen wat jou blij maakt. 20 jaar ervaring. Zandvoort e.o."
          />
        </MetaTags>
        <NavBar pageName="home" requestHash={window.location.hash} />
        <Hero />
        <HomeInleiding />
        <OverEsther />
        <VerzekeringKader />
        <HomeDoordenderen />
        <HomeAanbod />
        <HomePijlers />
        <HomeOpleveren />
        <Contact />
        <Footer />
      </React.Fragment>
    );
  }
}

export default Home;
